import React, { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import Tree from "react-d3-tree";
import NodeEditPanel from "../../../components/shared/NodeEditPannel";
import { dfs, verticalStepPathFunc } from "../../../components/constant/Dfs";
import { renderRectSvgNode } from "../../../components/constant/SvgNode";
import {
  chartAdd,
  chartEdit,
  deleteChart,
  getChart,
  unAssignUser,
} from "../../../services/frontService/ChartService";
import { toast } from "react-toastify";
import NodeDelete from "../../../components/modals/front/NodeDelete";
import AssignModal from "../../../components/common/DeleteModal";

export default function Chart() {
  const [isLoading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [empDetails, setEmpDetails] = useState({ loading: false, data: {} });
  const [isSidepanelOpen, setSidePanelOpen] = useState({
    isOpen: false,
  });
  const [assignModal, setAssignModal] = useState({
    isOpen: false,
  });
  const [isDeleteModal, setDeleteModal] = useState({
    isOpen: false,
    data: {},
  });
  const [deleteStatus, setDeleteStatus] = useState("single");

  const [tree, setTree] = useState({});

  const loadChart = useCallback(() => {
    setLoading(true);
    getChart().then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setTree(res?.docs[0]);
      }
    });
  }, []);

  useEffect(() => loadChart(), [loadChart]);

  const handleAddSibling = (nodeDatum, direction) => {
    const newSibling = {
      name: "New Sibling",
      attributes: {
        id: v4(),
      },
      isRoot: true,
      theme: "teal",
      children: [],
    };

    const newTree = dfs(
      nodeDatum?.attributes?.id,
      tree,
      newSibling,
      "addSibling",
      direction
    );

    if (newTree) {
      setTree(newTree);
    }
  };

  const addNode = (nodeDatum) => {
    setLoading(true);

    chartAdd({
      parentId: nodeDatum?._id,
      headerColor: "#71b483",
    }).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        toast.success("New Role Added");
        const newNode = { ...res?.data, children: [] };
        const newTree = dfs({
          id: nodeDatum?._id,
          tree,
          node: newNode,
          action: "add",
        });
        if (newTree) {
          setTree(newTree);
        }
      } else {
        toast.error("Failed to add new role");
      }
    });
  };

  const deleteNode = () => {
    const nodeDatum = isDeleteModal?.data;
    const nodeId = nodeDatum?._id;

    if (!nodeId) {
      toast.error("Node ID not found");
      return;
    }

    setIsDeleting(true);

    deleteChart({
      id: nodeId,
      payload: { deletationStatus: deleteStatus },
    }).then((res) => {
      setIsDeleting(false);
      if (res?.status === 200) {
        toast.success("Role Deleted");
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        const newTree = dfs({
          id: nodeId,
          tree,
          node: nodeDatum,
          action: "delete",
          isMultiple: deleteStatus === "multiple" ? true : false,
        });

        if (newTree) {
          setTree(newTree);
        }
      } else {
        toast.error("Failed to delete node");
      }
    });
  };

  const editNode = (nodeDatum) => {
    setEmpDetails({ loading: true, data: {} });

    return chartEdit({
      id: isSidepanelOpen?._id,
      payload: nodeDatum,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success(res?.message);
        const updatedNode = { ...res?.data };
        const newTree = dfs({
          id: isSidepanelOpen?._id,
          tree,
          node: updatedNode,
          action: "edit",
        });
        setEmpDetails({ loading: false, data: updatedNode });
        if (newTree) {
          setTree(newTree);
        }

        return res;
      } else {
        setEmpDetails({ loading: false, data: {} });
        toast.error(res?.message);
      }
    });
  };

  const usAssignNode = () => {
    setAssignModal((pre) => ({ ...pre, loading: true }));
    unAssignUser({
      id: assignModal?._id,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success(res?.message);
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setAssignModal((pre) => ({ ...pre, loading: false, isOpen: false }));
        setEmpDetails({ loading: false, data: {} });
        const newTree = dfs({
          tree,
          id: assignModal?._id,
          action: "unassign",
        });
        if (newTree) {
          setTree(newTree);
        }
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <div className="flex flex-row space-x-4 w-full h-full relative">
      <div className="w-full h-full relative overflow-hidden">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
            <div className="container">
              <h1 className="level-1 rectangle animate-pulse "></h1>
              <ol className="level-2-wrapper">
                <li>
                  <h2 className="level-2 rectangle animate-pulse "></h2>
                </li>
                <li>
                  <h2 className="level-2 rectangle animate-pulse"></h2>
                </li>
              </ol>
            </div>
          </div>
        )}

        <Tree
          data={tree}
          zoomable={true}
          orientation="vertical"
          pathFunc={verticalStepPathFunc}
          zoom={0.6}
          translate={{
            x: 600,
            y: 100,
          }}
          nodeSize={{ x: 280, y: 150 }}
          separation={{ siblings: 1, nonSiblings: 1.2 }}
          // enableLegacyTransitions={true}
          // transitionDuration={500}
          renderCustomNodeElement={(nodeInfo) =>
            renderRectSvgNode({
              addNode,
              nodeInfo,
              setAssignModal,
              setDeleteModal,
              isSidepanelOpen,
              setSidePanelOpen,
              handleAddSibling,
            })
          }
        />
      </div>
      <NodeEditPanel
        editNode={editNode}
        empDetails={empDetails}
        setEmpDetails={setEmpDetails}
        isSidepanelOpen={isSidepanelOpen}
        setSidePanelOpen={setSidePanelOpen}
      />
      <NodeDelete
        open={isDeleteModal?.isOpen}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        loading={isDeleting}
        deleteHandler={deleteNode}
        deleteStatus={deleteStatus}
        setDeleteStatus={setDeleteStatus}
        hasChild={isDeleteModal?.data?.children?.length > 0 ? true : false}
      />
      <AssignModal
        open={assignModal?.isOpen}
        loading={assignModal?.loading}
        onClose={() => setAssignModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={usAssignNode}
        title={"Unassign employee"}
        subTitle="You are trying to unassign an employee"
        deleteButtonIcon={"fa-regular fa-user-xmark"}
        deleteButtonLabel={"Un Assign"}
      />
    </div>
  );
}
