import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";

export default function NavigationItem({ data, selected = false }) {
  return (
    <>
      <div
        className={classNames(
          "group relative flex items-center justify-between transition-all duration-200 ease-in-out rounded-md pr-2 font-medium",
          (typeof selected == "function" ? selected(data) : selected)
            ? "border-orange-100 bg-orange-100 text-orange-600 "
            : "border-transparent bg-transparent  hover:bg-orange-100 hover:text-orange-500"
        )}
      >
        <Link
          to={data.link}
          className={classNames(
            "flex items-center w-full h-10 gap-2 px-2 text-base flex-shrink"
          )}
        >
          <i className={classNames("fa-fw", data.icon)}></i>
          <span className="text-sm">{data.name}</span>
        </Link>
      </div>
    </>
  );
}
