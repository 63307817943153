import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import RecentEmployee from "../../../components/sections/front/RecentEmployee";
import { classNames } from "../../../helpers/classNames";
import OurMissionManage from "../../../components/modals/front/OurMissionManage";
import { companyDetails } from "../../../services/frontService/CompanyService";
import { toast } from "react-toastify";
import CompanyManage from "../../../components/modals/front/CompanyManage";
import { left } from "@popperjs/core";
import WebsiteLinkManage from "../../../components/modals/front/WebsiteLinkManage";
import noImage from "../../../assets/images/no-results-bg.2d2c6ee3.png";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const [ourMissionModal, setMissionModal] = useState({
    isOpen: false,
    data: [],
  });
  const [websiteLinkModal, setWebsiteLinkModal] = useState({
    isOpen: false,
    data: [],
  });
  const [companyDetailsModal, setCompanyDetailsModal] = useState({
    isOpen: false,
    data: {},
  });
  useEffect(() => {
    companyDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
      } else {
        toast.error("Something went wrong!");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const data = details?.digitalAddress;

  const patternAfter =
    "after:content-[''] after:absolute after:z-[-1] after:w-1/2 after:h-40 after:bg-amber-500/15 after:-bottom-1/2 after:-right-10 after:rotate-[-15deg]";
  const patternBefore =
    "before:content-[''] before:absolute before:z-[-1] before:w-60 before:h-80 before:bg-cyan-500/20 before:-top-20 before:-right-40 before:rotate-[-15deg]";

  return (
    <div className="flex flex-row space-x-4 w-full pb-6">
      <div className="w-full relative z-0">
        <div className="w-full h-64 relative z-[-1] flex">
          <Image
            src={
              "https://media.licdn.com/dms/image/C4E12AQGW2kGWsFq8pw/article-cover_image-shrink_720_1280/0/1554257018707?e=2147483647&v=beta&t=nxyrubEfYZ199LSPuyqJaAzI4N9E3OdABBFvMWLff3E"
            }
            effect={"blur"}
            className="object-cover"
          />
        </div>
        <div className="w-10/12 mx-auto -mt-20 space-y-8">
          <div
            className={classNames(
              "relative w-full md:flex block lg:space-x-8 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow-sm border border-gray-100  z-0 overflow-hidden",
              patternBefore,
              patternAfter
            )}
          >
            <div className="flex justify-center">
              <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-lg flex-shrink-0 flex border border-slate-200">
                <Image
                  src={details?.image?.url || noImage}
                  effect={"blur"}
                  className="object-cover"
                />
              </div>
            </div>
            <div className="flex-shrink flex-grow space-y-2 flex flex-col">
              <div className="md:flex block justify-between">
                <div className="flex gap-4 items-center lg:justify-start justify-center">
                  <div className="text-gray-800 font-semibold text-3xl">
                    {details?.companyCredential?.name}
                  </div>
                </div>
                <div className="border border-orange-300 px-2 rounded-md text-orange-500 font-bold text-base flex items-center justify-center uppercase">
                  Total Employee : {details?.employeeCount?.count}
                </div>
              </div>
              <div className="space-y-4 mt-4">
                <div className="w-full flex gap-2 items-center">
                  <div className="text-xs text-slate-500 flex-shrink-0">
                    <i class="fa-regular fa-fw fa-envelope"></i>
                  </div>
                  <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                    <div className="text-sm text-slate-500">
                      {details?.companyCredential?.email}
                    </div>
                  </div>
                </div>
                <div className="w-full flex gap-2 items-center">
                  <div className="text-xs text-slate-500 flex-shrink-0">
                    <i class="fa-regular fa-fw fa-phone"></i>
                  </div>
                  <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                    <div className="text-sm text-slate-500">
                      +91 {details?.companyCredential?.phone}
                    </div>
                  </div>
                </div>
                {/* <div className="w-full flex gap-2 items-center">
                  <div className="text-xs text-slate-500 flex-shrink-0">
                    <i class="fa-regular fa-fw fa-location-dot"></i>
                  </div>
                  <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                    <div className="text-sm text-slate-500">
                      132 My Street, Kingston, New York 12401, United States
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="flex justify-end items-end !mt-auto">
                <Button
                  buttonClasses="!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                  buttonHasLink={false}
                  buttonType="button"
                  buttonIconPosition={left}
                  buttonIcon={"fa-regular fa-pen-to-square"}
                  buttonLabelClasses="!text-xs !font-medium"
                  buttonFunction={() =>
                    setCompanyDetailsModal({
                      isOpen: true,
                      data: {
                        companyCredential: details?.companyCredential,
                        image: details?.image,
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-6 bg-white rounded-md px-6 ">
            <div className="col-span-3 space-y-10 border-r pr-6 py-8">
              <div className="relative space-y-2">
                <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                  <div className="flex justify-between items-center">
                    <div className="text-gray-800 font-bold text-lg capitalize">
                      {details?.companyInfo?.goal}
                    </div>
                    <div className="flex-shrink-0">
                      <Button
                        buttonClasses="!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                        buttonHasLink={false}
                        buttonType="button"
                        buttonIconPosition={left}
                        buttonIcon={"fa-regular fa-pen-to-square"}
                        buttonLabelClasses="!text-xs !font-medium"
                        buttonFunction={() =>
                          setMissionModal({
                            isOpen: true,
                            data: details?.companyInfo,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="text-slate-500 text-sm leading-normal">
                    {isExpanded
                      ? details?.companyInfo?.description
                      : `${details?.companyInfo?.description.substring(
                          0,
                          350
                        )}...`}
                    <button
                      onClick={toggleReadMore}
                      className="text-orange-500 ml-2"
                    >
                      {isExpanded ? "Show Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="relative space-y-3">
                <div className="text-gray-800 font-bold text-lg">
                  Administrator Details
                </div>
                <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                  <div className="flex items-start gap-5">
                    <div className="w-24 aspect-square overflow-hidden flex rounded-lg flex-shrink-0">
                      <Image
                        src={details?.companyAdministrator?.image?.url}
                        effect={"blur"}
                        className={"object-cover"}
                      />
                    </div>
                    <div className="flex-shrink flex-grow space-y-2">
                      <div className="text-lg text-gray-800 font-semibold">
                        {details?.companyAdministrator?.firstName +
                          " " +
                          details?.companyAdministrator?.lastName}
                      </div>
                      <div className="relative space-y-1">
                        <div className="flex items-center gap-2">
                          <div className="flex-shrink-0 text-slate-500 text-xs border-r border-r-slate-300 pr-1">
                            <i class="fa-regular fa-fw fa-envelope"></i>
                          </div>
                          <div className="flex-grow flex-shrink text-sm text-slate-500">
                            {details?.companyAdministrator?.email}
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="flex-shrink-0 text-slate-500 text-xs border-r border-r-slate-300 pr-1">
                            <i class="fa-regular fa-fw fa-phone"></i>
                          </div>
                          <div className="flex-grow flex-shrink text-sm text-slate-500">
                            +91 {details?.companyAdministrator?.phone}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 flex items-end">
                      <Button
                        buttonClasses={
                          "!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                        }
                        buttonHasLink={false}
                        buttonType={"button"}
                        buttonIconPosition={left}
                        buttonIcon={"fa-regular fa-pen-to-square"}
                        buttonLabelClasses={"!text-xs !font-medium"}
                        buttonFunction={() => navigate("/profile")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 space-y-2 py-8">
              <div className="flex justify-between items-center">
                <div className="text-gray-800 font-bold text-xl">
                  Recent Employees
                </div>
                <Button
                  buttonClasses={
                    "!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                  }
                  buttonHasLink={false}
                  buttonType={"button"}
                  buttonIconPosition={left}
                  buttonIcon={"fa-regular fa-pen-to-square"}
                  buttonLabelClasses={"!text-xs !font-medium"}
                  buttonFunction={() => setWebsiteLinkModal({ isOpen: true , data: data })}
                />
              </div>
              <div className="w-full border border-gray-100 bg-white shadow rounded-lg min-h-[400px] max-h-[640px] overflow-y-scroll scrollbar">
                <div className="overflow-auto divide-y divide-slate-200">
                  {data?.length > 0 &&
                    data?.map((item, index) => (
                      <div className="relative" key={index}>
                        <RecentEmployee data={item} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurMissionManage
        ourMissionModal={ourMissionModal}
        setMissionModal={setMissionModal}
        setDetails={setDetails}
      />
      <WebsiteLinkManage
        websiteLinkModal={websiteLinkModal}
        setWebsiteLinkModal={setWebsiteLinkModal}
        setDetails={setDetails}
      />
      <CompanyManage
        companyDetailsModal={companyDetailsModal}
        setCompanyDetailsModal={setCompanyDetailsModal}
        setDetails={setDetails}
      />
    </div>
  );
};

export default CompanyDetails;
