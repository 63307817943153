import React from "react";
import Button from "../../form/Button";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";

const InviteModal = () => {
  const validation = {
    // name: { required: true, message: "Please enter employee name" },
    // email: { required: true, message: "Please enter employee email" },
    // reportTo: { required: true, message: "Please select a employee" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const data = [
    {
      id: 1035,
      type: 2,
      role: "user",
      name: "Prokash Malo",
      email: "prokashmalo981@gmail.com",
      encode_password: "UHJva2FzaEAxMjM=",
      phone: 8965896589,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-27T07:18:20.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1034,
      type: 2,
      role: "user",
      name: "Zara Hart",
      email: "zarahart@gmail.com",
      encode_password: "WmFyYUAzMjE=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-20T12:42:36.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1033,
      type: 2,
      role: "user",
      name: "Smritikana Banerjee",
      email: "smritikana@symlinktech.com",
      encode_password: "QWRtaW5AMTIz",
      phone: 8282828282,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-12T09:52:30.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1027,
      type: 2,
      role: "user",
      name: "Trayee Ghosh",
      email: "trayee@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:50:23.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1026,
      type: 2,
      role: "user",
      name: "Pilu Malo ",
      email: "pilu@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: 785545454545,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:21:38.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1035,
      type: 2,
      role: "user",
      name: "Prokash Malo",
      email: "prokashmalo981@gmail.com",
      encode_password: "UHJva2FzaEAxMjM=",
      phone: 8965896589,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-27T07:18:20.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1034,
      type: 2,
      role: "user",
      name: "Zara Hart",
      email: "zarahart@gmail.com",
      encode_password: "WmFyYUAzMjE=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-20T12:42:36.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1033,
      type: 2,
      role: "user",
      name: "Smritikana Banerjee",
      email: "smritikana@symlinktech.com",
      encode_password: "QWRtaW5AMTIz",
      phone: 8282828282,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-12T09:52:30.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1027,
      type: 2,
      role: "user",
      name: "Trayee Ghosh",
      email: "trayee@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:50:23.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1026,
      type: 2,
      role: "user",
      name: "Pilu Malo ",
      email: "pilu@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: 785545454545,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:21:38.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1035,
      type: 2,
      role: "user",
      name: "Prokash Malo",
      email: "prokashmalo981@gmail.com",
      encode_password: "UHJva2FzaEAxMjM=",
      phone: 8965896589,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-27T07:18:20.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1034,
      type: 2,
      role: "user",
      name: "Zara Hart",
      email: "zarahart@gmail.com",
      encode_password: "WmFyYUAzMjE=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-20T12:42:36.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1033,
      type: 2,
      role: "user",
      name: "Smritikana Banerjee",
      email: "smritikana@symlinktech.com",
      encode_password: "QWRtaW5AMTIz",
      phone: 8282828282,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-12T09:52:30.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1027,
      type: 2,
      role: "user",
      name: "Trayee Ghosh",
      email: "trayee@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:50:23.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1026,
      type: 2,
      role: "user",
      name: "Pilu Malo ",
      email: "pilu@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: 785545454545,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:21:38.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1035,
      type: 2,
      role: "user",
      name: "Prokash Malo",
      email: "prokashmalo981@gmail.com",
      encode_password: "UHJva2FzaEAxMjM=",
      phone: 8965896589,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-27T07:18:20.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1034,
      type: 2,
      role: "user",
      name: "Zara Hart",
      email: "zarahart@gmail.com",
      encode_password: "WmFyYUAzMjE=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-20T12:42:36.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1033,
      type: 2,
      role: "user",
      name: "Smritikana Banerjee",
      email: "smritikana@symlinktech.com",
      encode_password: "QWRtaW5AMTIz",
      phone: 8282828282,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-12T09:52:30.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1027,
      type: 2,
      role: "user",
      name: "Trayee Ghosh",
      email: "trayee@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:50:23.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1026,
      type: 2,
      role: "user",
      name: "Pilu Malo ",
      email: "pilu@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: 785545454545,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:21:38.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
  ];

  const onSubmit = () => {};
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="relative">
          <div className="grid grid-cols-12 gap-4">
            <div className="relative col-span-12">
              <SelectDropdownSearch
                label={"Company employees"}
                placeholder={""}
                isImage={true}
                xPlacement={"bottom"}
                buttonArrowIcon={"fa-solid fa-chevron-down"}
                tabTextColor={"text-slate-700"}
                selectName={"reportTo"}
                isSearch={true}
                // onSearch={(key) => setKeyword(key)}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                // onChange={handleChange}
                value={values?.reportTo}
                dropdownData={data?.map((item) => ({
                  name: item?.name,
                  _id: item?.id,
                  url: item?.image_name_url,
                  subItem: "Developer",
                }))}
              />
            </div>
            <div className="col-span-12 border-t mt-1"></div>
            <div className="relative col-span-12">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"Full name"}
                inputPlaceholder={"Full Name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.name}
                inputName={"name"}
                onChange={handleChange}
                {...formValidate(errors, "name")}
              />
            </div>
            <div className="relative col-span-12">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                inputGroupPosition={"left"}
                label={"Email"}
                inputClasses={"pl-2"}
                inputPlaceholder={"Enter Employee Email"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.email}
                inputName={"email"}
                onChange={handleChange}
                {...formValidate(errors, "email")}
              />
            </div>
            <div className="relative col-span-12">
              <SelectDropdownSearch
                label={"Position the sucession plan is being created for:"}
                placeholder={""}
                isImage={true}
                xPlacement={"bottom"}
                buttonArrowIcon={"fa-solid fa-chevron-down"}
                tabTextColor={"text-slate-700"}
                selectName={"position"}
                isSearch={true}
                // onSearch={(key) => setKeyword(key)}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                // onChange={handleChange}
                value={values?.position}
                dropdownData={data?.map((item) => ({
                  name: item?.name,
                  _id: item?.id,
                  url: item?.image_name_url,
                  subItem: "Developer",
                }))}
              />
            </div>
            <div className="relative col-span-7 mt-2">
              <Checkbox
                checkboxName={"filter_checkbox"}
                checkboxInputclassName={""}
                // checked={selected?.some((data) => data?.value === item?.value)}
                checkboxLabel={"Create adicional administrator"}
                checkboxLableclassName={"font-normal text-xs"}
              />
            </div>
            <div className="relative col-span-5  mt-2">
              <Checkbox
                checkboxName={"filter_checkbox"}
                checkboxInputclassName={""}
                // checked={selected?.some((data) => data?.value === item?.value)}
                checkboxLabel={"Invite board member"}
                checkboxLableclassName={"font-normal text-xs"}
              />
            </div>
          </div>
          <div className="mt-8 pt-4 ">
            <div className="flex items-center justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonLabel={"Invite"}
                buttonLabelClasses={"uppercase"}
                buttonClasses={
                  "!text-white !bg-orange-500  focus-visible:outline justify-center rounded-md border border-orange-500"
                }
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteModal;
