import Button from "../../../form/Button";

export default function LatestNews() {
  return (
    <div className="p-2">
      <p className="text-xl font-semibold text-slate-600 mb-4">Latest News</p>
      <div className="border border-slate-300 rounded-lg p-6 bg-white shadow-sm">
        <div className="border-2 border-dashed rounded-lg p-6 text-center flex flex-col items-center justify-center gap-4">
          <div className="w-20 h-20 flex items-center justify-center rounded-full bg-orange-50">
            <i className="fa-light fa-bullhorn text-3xl text-orange-600" />
          </div>
          <Button
            buttonIconPosition="left"
            buttonLabel="Announcements"
            buttonIcon="fa-light fa-plus"
            buttonClasses="!bg-white !text-orange-600 !border-orange-100 !font-medium !text-sm hover:!bg-orange-50"
          />
          <p className="text-center text-sm font-medium text-slate-700">
            Share updates, reminders, or upcoming events with your entire
            company. Post an announcement now or schedule one for later.
          </p>
        </div>
      </div>
    </div>
  );
}
