import React from "react";
import Image from "../../elements/Image";
import noImage from "../../../assets/images/no-image.png";
import website from "../../../assets/images/website.jpg";
import instagram from "../../../assets/images/instagram_PNG10.png";
import facebook from "../../../assets/images/facebook-logo-3.png";
import linkedIn from "../../../assets/images/linkedIn_PNG7.png";
import twitter from "../../../assets/images/Twitter-Logo.jpg"

const RecentEmployee = ({ data }) => {
  const renderImageContent = (fieldName) => {
    if (fieldName === "website") {
      return website;
    } else if (fieldName === "instagram") {
      return instagram;
    } else if (fieldName === "facebook") {
      return facebook;
    } else if (fieldName === "twitter") {
      return twitter;
    } else if (fieldName === "linkedin") {
      return linkedIn;
    } else {
      return noImage;
    }
  };

  return (
    <>
      <div className="relative flex items-center space-x-4 py-4 px-5 ">
        <div className="w-12 h-12 aspect-square rounded-md overflow-hidden flex flex-shrink-0">
          <Image
            src={renderImageContent(data?.fieldName)}
            alt=""
            className="w-full h-full object-cover"
            effect="blur"
          />
        </div>
        <div className="flex-shrink flex-grow">
          <div className="text-base font-medium text-gray-800">
            {data?.fieldName}
          </div>
          <div className="text-xs text-slate-400">{data?.value}</div>
        </div>
      </div>
    </>
  );
};

export default RecentEmployee;
