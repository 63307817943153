import { Link } from "react-router-dom";
import Button from "../../../form/Button";
import EmpImage from "../../../../assets/images/member-02.webp";
import Image from "../../../elements/Image";

export default function ForYou() {
  return (
    <div className="col-span-3 flex flex-col gap-4 p-2">
      <p className="text-xl font-semibold text-slate-600">For You</p>
      <Button
        buttonIconPosition="left"
        buttonLabel="Add a Status"
        buttonIcon="fa-regular fa-calendar"
        buttonClasses="!bg-white !h-12 !text-slate-600 !border-slate-300 !font-medium !text-sm hover:!bg-slate-100"
      />
      <Button
        buttonIconPosition="left"
        buttonLabel="Applaud a Coworker"
        buttonIcon="fa-regular fa-hands-clapping"
        buttonClasses="!bg-white !h-12 !text-slate-600 !border-slate-300 !font-medium !text-sm hover:!bg-slate-100"
      />
      <Button
        buttonIconPosition="left"
        buttonLabel="Edit Your Profile"
        buttonIcon="fa-regular fa-circle-user"
        buttonClasses="!bg-white !h-12 !text-slate-600 !border-slate-300 !font-medium !text-sm hover:!bg-slate-100"
      />
      <div className="border border-slate-300 rounded-md p-4  text-slate-800">
        <p className="text-sm font-semibold">Your Org Memberships</p>
        <div className="flex flex-col gap-2 mt-3">
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-users" />
              <p>Employees</p>
            </div>
            <span>5</span>
          </div>
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-flag" />
              <p>Departments</p>
            </div>
            <span>3</span>
          </div>
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-building-user" />
              <p>Position</p>
            </div>
            <span>3</span>
          </div>
          <Link
            to="/chart"
            className="text-orange-600 font-semibold text-sm hover:underline mt-2"
          >
            View Org Chart <i className="fa-regular fa-arrow-right ml-1" />
          </Link>
          <Link
            to="/directory"
            className="text-orange-600 font-semibold text-sm hover:underline"
          >
            View Directory <i className="fa-regular fa-arrow-right ml-1" />
          </Link>
        </div>
      </div>
      <div className="border border-slate-300 rounded-md p-4 text-slate-800">
        <p className="text-sm font-semibold mb-4">Your Upcoming Events</p>

        <div className="space-y-4">
          <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
            <div className="w-12 h-w-12 relative">
              <Image
                src={EmpImage}
                alt={""}
                effect={"blur"}
                className="w-full h-full object-cover rounded-full border"
              />
              <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                <i className="fa-solid fa-cake-candles" />
              </span>
            </div>
            <div className="flex-1">
              <p className="text-sm font-semibold text-slate-700">
                Work Anniversary
              </p>
              <p className="text-xs text-slate-500">
                17 Jul • Celebrating 15 years!
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
            <div className="w-12 h-w-12 relative">
              <Image
                src={EmpImage}
                alt={""}
                effect={"blur"}
                className="w-full h-full object-cover rounded-full border"
              />
              <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                <i className="fa-solid fa-cake-candles" />
              </span>
            </div>
            <div className="flex-1">
              <p className="text-sm font-semibold text-slate-700">
                Work Anniversary
              </p>
              <p className="text-xs text-slate-500">
                17 Jul • Celebrating 15 years!
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
            <div className="w-12 h-w-12 relative">
              <Image
                src={EmpImage}
                alt={""}
                effect={"blur"}
                className="w-full h-full object-cover rounded-full border"
              />
              <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                <i className="fa-solid fa-cake-candles" />
              </span>
            </div>
            <div className="flex-1">
              <p className="text-sm font-semibold text-slate-700">
                Work Anniversary
              </p>
              <p className="text-xs text-slate-500">
                17 Jul • Celebrating 15 years!
              </p>
            </div>
          </div>
          <Button
            buttonIconPosition="left"
            buttonLabel="Add a status"
            buttonIcon="fa-light fa-plus"
            buttonClasses="!bg-white !text-orange-600 !border-none !font-medium !text-sm hover:!bg-orange-50"
          />
        </div>
      </div>
    </div>
  );
}
