import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getVerifyTokan, profileDetails } from "../../services/ProfileService";
import AdminSideBar from "../shared/AdminSidebar";
import AdminHeader from "../shared/AdminHeader";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/slice/profileSlice,";
import { getSettings } from "../../redux/slice/settingsSlice";
import { siteSettingDetails } from "../../services/SettingService";

export default function AdminLayout() {
  const authToken = localStorage.getItem("auth_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const profile = useSelector((state) => state?.profile?.data);
  const settings = useSelector((state) => state?.settings?.data);
  
  const loadVerifyToken = useCallback(async () => {
    try {
      const res = await getVerifyTokan();
      if (res?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
      } else {
        localStorage.setItem(
          "role",
          res?.data?.data?.role?.find(
            (item) => item?.code === "COMPANY-ADMINISTRATOR"
          )?.code === "COMPANY-ADMINISTRATOR"
            ? "COMPANY-ADMINISTRATOR"
            : res?.data?.data?.role?.find((item) => item?.code === "ADMIN")
                ?.code === "ADMIN"
            ? "ADMIN"
            : ""
        );
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
      } else {
        console.error("Unexpected error:", error);
        localStorage.removeItem("auth_token");
        navigate("/signin");
      }
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      loadVerifyToken();
    }
  }, [loadVerifyToken, authToken]);

  const fetchProfile = useCallback(() => {
    profileDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
      }
    });
  }, [authToken]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    siteSettingDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(getSettings(res?.data));
      }
    });
  }, []);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon?.url;
  }
  favIconChange();

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        <div
          className={
            "fixed h-screen flex transition-all duration-200 z-60 " +
            (isMenuOpen
              ? "-translate-x-full lg:translate-x-0 w-60 lg:w-16"
              : "translate-x-0 w-60 lg:w-60")
          }
        >
          <AdminSideBar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div
          className={
            "relative w-full flex flex-col transition-all duration-200 " +
            (isMenuOpen ? "p-0 lg:pl-16" : "p-0 lg:pl-60")
          }
        >
          <AdminHeader profile={profile} />
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] p-3 md:p-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
