import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import {
  departMentAdd,
  departMentEdit,
} from "../../../services/frontService/DepartmentService";
import { toast } from "react-toastify";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";

export default function DepartmentMange({
  isDepModal,
  setList,
  setDepModal = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const data = isDepModal?.data;
  const validation = {
    name: { required: true, message: "Please enter  department name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        name: data?.name,
      });
    }
  }, [data]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        name: values?.name,
      };

      const response = data?._id
        ? await departMentEdit({
            ...payload,
            id: data?._id,
          })
        : await departMentAdd(payload);

      setLoading(false);

      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setDepModal((pre) => ({ ...pre, isOpen: false }));
        setList((prev) => ({
          ...prev,
          data: prev?.data
            ?.map((item) =>
              item?._id === response?.data?._id ? response?.data : item
            )
            .concat(
              !prev?.data?.some((item) => item?._id === response?.data?._id)
                ? [response?.data]
                : []
            ),
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount:
              response?.status === 201
                ? pre?.departmentCount + 1
                : pre?.departmentCount,
          }))
        );
      } else {
        toast.error(response?.message || response?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      title={data?._id ? "Edit Department" : "Add Department"}
      size="lg"
      open={isDepModal?.isOpen}
      onClose={() => {
        setDepModal((pre) => ({ ...pre, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
    >
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="relative col-span-12">
          <Input
            isInputGroup={true}
            inputGroupIcon={"fa-light fa-building-user text-slate-400"}
            inputGroupPosition={"left"}
            label={"Name"}
            inputPlaceholder={"Enter department name"}
            inputClasses={"pl-2"}
            labelClasses={"!text-sm !font-medium"}
            inputType={"text"}
            value={values.name}
            inputName={"name"}
            onChange={handleChange}
            charLimit={200}
            {...formValidate(errors, "name")}
          />
        </div>
        <div className="flex items-center justify-end mt-4">
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            buttonIcon={
              data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
          />
        </div>
      </form>
    </Modal>
  );
}
