export const status_filter = [
  { value: 1, name: "Active", type: "checkbox" },
  { value: 2, name: "In Active", type: "checkbox" },
];
export const featured_filter = [
  { value: 1, name: "Featured", type: "checkbox" },
  { value: 0, name: "Non-featured", type: "checkbox" },
];
export const trending_filter = [
  { value: 1, name: "Trending", type: "checkbox" },
  { value: 0, name: "Non-trending", type: "checkbox" },
];
export const approved_filter = [
  { value: true, name: "Approved", type: "checkbox" },
  { value: false, name: "Non-approved", type: "checkbox" },
];
export const member_filter = [
  { value: true, name: "Member", type: "checkbox" },
  { value: false, name: "Not-member", type: "checkbox" },
];
export const email_filter = [
  { value: true, name: "Verified", type: "checkbox" },
  { value: false, name: "Not-verified", type: "checkbox" },
];
export const attr_filter = [
  { value: true, name: "Value", type: "checkbox" },
  { value: false, name: "Attribute", type: "checkbox" },
];
export const order_status_filter = [
  { value: "processing", name: "Processing", type: "checkbox" },
  { value: "shipped", name: "Shipped", type: "checkbox" },
  { value: "delivered", name: "Delivered", type: "checkbox" },
  { value: "cancelled", name: "Cancelled", type: "checkbox" },
];
export const payment_status_filter = [
  { value: "pending", name: "Pending", type: "checkbox" },
  { value: "success", name: "Success", type: "checkbox" },
  { value: "failed", name: "Failed", type: "checkbox" },
];
export const payment_method_filter = [
  { value: "online", name: "Online", type: "checkbox" },
  { value: "cash", name: "Cash", type: "checkbox" },
];

// export const treeData = {
//   name: "hidden",
//   children: [
//     {
//       name: "James Milner",
//       role: "Chief Executive",
//       position: "Chief Executive Owner",
//       theme: "teal",
//       isRoot: true,
//       attributes: {
//         id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f2",
//       },
//       children: [
//         {
//           name: "Carl Anderson",
//           role: "Technology",
//           position: "Chief Technology Owner",
//           theme: "amber",
//           attributes: {
//             id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f3",
//           },
//           children: [
//             {
//               name: "S. Chandler",
//               role: "Technology",
//               position: "Head of mobile",
//               theme: "brick",
//               attributes: {
//                 id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f4",
//               },
//               children: [],
//             },
//             {
//               name: "P. Williamson",
//               role: "Technology",
//               position: "Head of mobile",
//               theme: "green",
//               attributes: {
//                 id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f5",
//               },
//               children: [],
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: "Jack Anderson",
//       role: "Chief Executive",
//       position: "Chief Executive Owner",
//       theme: "teal",
//       isRoot: true,
//       attributes: {
//         id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f6",
//       },
//       children: [],
//     },
//     {
//       name: "Jack Anderson",
//       role: "Chief Executive",
//       position: "Chief Executive Owner",
//       theme: "teal",
//       isRoot: true,
//       attributes: {
//         id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f6",
//       },
//       children: [],
//     },
//   ],
// };
export const treeData = {
  name: "hidden",
  children: [
    {
      name: "James Milner",
      nodeType: "card",
      role: "Chief Executive",
      position: "Chief Executive Owner",
      theme: "teal",
      isRoot: true,
      attributes: {
        id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f2",
      },
      children: [
        {
          name: "Department A",
          nodeType: "department",
          children: [
            {
              name: "Carl Anderson",
              nodeType: "card",
              role: "Technology",
              position: "Chief Technology Owner",
              theme: "amber",
              attributes: {
                id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f3",
              },
              children: [
                {
                  name: "S. Chandler",
                  nodeType: "card",
                  role: "Technology",
                  position: "Head of mobile",
                  theme: "brick",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f4",
                  },
                  children: [],
                },
                {
                  name: "P. Williamson",
                  nodeType: "card",
                  role: "Technology",
                  position: "Head of mobile",
                  theme: "green",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f5",
                  },
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Jack Anderson",
      nodeType: "card",
      role: "Chief Executive",
      position: "Chief Executive Owner",
      theme: "teal",
      isRoot: true,
      attributes: {
        id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f6",
      },
      children: [
        {
          name: "Department B",
          nodeType: "department",
          children: [
            {
              name: "Melissa Nakamura",
              nodeType: "card",
              role: "Marketing",
              position: "Chief Marketing Owner",
              theme: "navy",
              attributes: {
                id: "411d9783-85ba-41e5-a6a3-5e1cca3d292f7",
              },
              children: [
                {
                  name: "O. Lopez",
                  nodeType: "card",
                  role: "Marketing",
                  position: "Head of Marketing Data",
                  theme: "amber",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3dsccs294f7",
                  },
                  children: [
                    {
                      name: "K. Jackson",
                      nodeType: "card",
                      role: "Marketing",
                      position: "Social Media",
                      theme: "brick",
                      attributes: {
                        id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f4",
                      },
                      children: [],
                    },
                    {
                      name: "J. Brown",
                      nodeType: "card",
                      role: "Marketing",
                      position: "Android  Software Engineer",
                      theme: "green",
                      attributes: {
                        id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f7",
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              name: "Taylor Watford",
              nodeType: "card",
              role: "Finance",
              position: "Chief Finance Owner",
              theme: "green",
              attributes: {
                id: "411d9783-85ba-41e5e-a6a3-5e1cca3d294f7",
              },
              children: [
                {
                  name: "N. Jones",
                  nodeType: "card",
                  role: "Finance",
                  position: "Product Design Senior",
                  theme: "navy",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f7",
                  },
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Jack Anderson",
      nodeType: "card",
      role: "Chief Executive",
      position: "Chief Executive Owner",
      theme: "teal",
      isRoot: true,
      attributes: {
        id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f6",
      },
      children: [
        {
          name: "Department C",
          nodeType: "department",
          children: [
            {
              name: "Melissa Nakamura",
              nodeType: "card",
              role: "Marketing",
              position: "Chief Marketing Owner",
              theme: "navy",
              attributes: {
                id: "411d9783-85ba-41e5-a6a3-5e1cca3d292f7",
              },
              children: [
                {
                  name: "O. Lopez",
                  nodeType: "card",
                  role: "Marketing",
                  position: "Head of Marketing Data",
                  theme: "amber",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3dsccs294f7",
                  },
                  children: [
                    {
                      name: "K. Jackson",
                      nodeType: "card",
                      role: "Marketing",
                      position: "Social Media",
                      theme: "brick",
                      attributes: {
                        id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f4",
                      },
                      children: [],
                    },
                    {
                      name: "J. Brown",
                      nodeType: "card",
                      role: "Marketing",
                      position: "Android  Software Engineer",
                      theme: "green",
                      attributes: {
                        id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f7",
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              name: "Taylor Watford",
              nodeType: "card",
              role: "Finance",
              position: "Chief Finance Owner",
              theme: "green",
              attributes: {
                id: "411d9783-85ba-41e5e-a6a3-5e1cca3d294f7",
              },
              children: [
                {
                  name: "N. Jones",
                  nodeType: "card",
                  role: "Finance",
                  position: "Product Design Senior",
                  theme: "navy",
                  attributes: {
                    id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f7",
                  },
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
