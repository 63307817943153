import React from "react";
import SidebarBlock from "./SidebarBlock";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  const pageData1 = [
    {
      _id: 1,
      name: "Home",
      icon: "fa-light fa-house",
      link: "/",
      isActive: false,
    },
    {
      _id: 2,
      name: "Directory",
      icon: "fa-light fa-folder-tree",
      link: "/directory/employees",
      isActive: false,
    },
    {
      _id: 3,
      name: "Company",
      icon: "fa-light fa-buildings",
      link: "/company",
      isActive: false,
    },
    {
      _id: 4,
      name: "Org Chart",
      icon: "fa-light fa-sitemap",
      link: "/chart",
      isActive: false,
    },
    {
      _id: 5,
      name: "Calendar",
      icon: "fa-light fa-calendar",
      link: "/calendar",
      isActive: false,
    },
    // {
    //   _id: 5,
    //   name: "Calendar",
    //   icon: "fa-light fa-calendar",
    //   link: "/",
    //   isActive: false,
    // },
  ];

  const pageData2 = [
    {
      _id: 1,
      name: "Onboarding",
      icon: "fa-light fa-gauge-high",
      link: "/w/1/projects",
    },
    {
      _id: 2,
      name: "Reports",
      icon: "fa-light fa-chart-line-up-down",
      link: "/w/1/clients",
    },
  ];

  const optionPageData = [
    {
      _id: 1,
      name: "Settings",
      icon: "fa-light fa-cog",
      link: "/w/1/projects",
    },
    {
      _id: 2,
      name: "Help",
      icon: "fa-light fa-question-circle",
      link: "/w/1/clients",
    },
  ];

  return (
    <>
      <aside className="w-full bg-slate-50 fixed left-0 top-0 xl:relative h-screen max-h-[calc(100vh-4rem)] py-0 px-4 overflow-auto scrollbar flex border-r">
        <div className="w-full divide-y divide-slate-200 flex flex-col">
          <SidebarBlock
            title="Pages"
            data={pageData1}
            selected={(data) => {
              return (
                location.pathname === data?.link ||
                (data?.name === "Directory" &&
                  location.pathname.startsWith("/directory"))
              );
            }}
          />
          {/* <SidebarBlock
            title="Analysis"
            data={pageData2}
            selected={(data) => {
              return location.pathname === data?.link;
            }}
          /> */}
          <div className="mt-auto">
            <SidebarBlock
              title="Options"
              data={optionPageData}
              selected={(data) => {
                return location.pathname === data?.link;
              }}
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
