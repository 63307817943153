import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/front/home/Home";
import Chart from "./pages/front/chart/Chart";
import AuthLayout from "./layouts/auth/AuthLayout";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import ForgotPassword from "./pages/auth/ForgotPassword";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import CompanyList from "./pages/admin/companies/CompanyList";
import EmployeeList from "./pages/admin/employees/EmployeeList";
import AdminLayout from "./layouts/auth/AdminLayout";
import AdminList from "./pages/admin/admins/AdminList";
import EmailTemplate from "./pages/admin/email-template/EmailTemplate";
import Settings from "./pages/admin/settings/Settings";
import AdminLogin from "./pages/admin/signIn/AdminLogin";
import ManageCompany from "./pages/admin/companies/ManageCompany";
import EditProfile from "./pages/admin/profile/EditProfile";
import ManageEmailTemplate from "./pages/admin/email-template/ManageEmailTemplate";

import ManageAdmin from "./pages/admin/admins/ManageAdmin";
import EmployeeDetails from "./components/sections/front/directory/EmployeeDetails";
import AdminCompanyDetails from "./pages/admin/companies/AdminCompanyDetails";
import Departments from "./components/sections/front/directory/DepartmentSection";
import Positions from "./components/sections/front/directory/PositionSection";
import Company from "./pages/front/company/CompanyDetails";
import MyProfile from "./pages/front/profile/MyProfile";
import Employees from "./components/sections/front/directory/EmployeeSection";
import Signin from "./pages/front/signin/Signin";
import AcceptAdministrator from "./pages/auth/AcceptAdministrator";
import FrontPasswordChange from "./pages/front/profile/FrontPasswordChange";
import Calendar from "./pages/front/calendar/Calendar";

const authRoutes = [
  { path: "/signin", title: "Sign In", element: <Signin /> },
  { path: "/admin/signin", title: "Admin Login", element: <AdminLogin /> },
  {
    path: "/forgotPassword",
    title: "Forgot Password",
    element: <ForgotPassword />,
  },
  {
    path: "/accept-administrator",
    title: "Accept Administrator",
    element: <AcceptAdministrator />,
  },
];

const frontRoutes = [
  { path: "/", component: <Home /> },
  { path: "/chart", component: <Chart /> },
  { path: "/directory/employees", component: <Employees /> },
  { path: "/directory/employee/:id", component: <EmployeeDetails /> },
  { path: "/change-password", component: <FrontPasswordChange /> },
  { path: "/directory/departments", component: <Departments /> },
  { path: "/directory/positions", component: <Positions /> },
  { path: "/calendar", component: <Calendar /> },
  { path: "/profile", component: <MyProfile /> },
  { path: "/company", component: <Company /> },
];

const adminRoutes = [
  { path: "/admin/dashboard" || "/admin", component: <Dashboard /> },
  { path: "/admins", component: <AdminList /> },
  { path: "/admin/add", component: <ManageAdmin /> },
  { path: "/admin/edit/:id", component: <ManageAdmin /> },
  { path: "/admin/companies", component: <CompanyList /> },
  { path: "/admin/company/add", component: <ManageCompany /> },
  { path: "/admin/company/edit/:id", component: <ManageCompany /> },
  { path: "/admin/company/details/:id", component: <AdminCompanyDetails /> },
  { path: "/admin/employees", component: <EmployeeList /> },
  { path: "/admin/email-template", component: <EmailTemplate /> },
  { path: "/admin/site-settings", component: <Settings /> },
  { path: "/admin/profile", component: <EditProfile /> },
  {
    path: "/admin/email-template/edit/:id",
    component: <ManageEmailTemplate />,
  },
];

const CreateRoutes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        {authRoutes.map(({ path, title, element }) => (
          <Route
            key={path}
            path={path}
            element={<FreeAuthRoute title={title}>{element}</FreeAuthRoute>}
          />
        ))}

        {frontRoutes.map(({ path, component }) => (
          <Route key={path} path={path} element={<AuthLayout />}>
            <Route
              path={path}
              element={
                <ProtectedRoute getAccess={["COMPANY-ADMINISTRATOR"]}>
                  {component}
                </ProtectedRoute>
              }
            />
          </Route>
        ))}

        {adminRoutes.map(({ path, component }) => (
          <Route key={path} path={path} element={<AdminLayout />}>
            <Route
              path={path}
              exact
              element={
                <ProtectedRoute getAccess={["ADMIN"]}>
                  {component}
                </ProtectedRoute>
              }
            />
          </Route>
        ))}

        <Route
          path="/admin"
          element={
            <ProtectedRoute getAccess={["ADMIN"]}>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default CreateRoutes;
