import { apiRequest } from "../../util/apiUtils";

export const getEmployeeList = async (params) => {
  try {
    const res = await apiRequest("GET", `/user/users-list-all`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const employeeAdd = async (payload) => {
  try {
    const res = await apiRequest(
      "POST",
      `/user/employee-add`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const employeeEdit = async (payload) => {
  try {
    const res = await apiRequest(
      "PUT",
      `/user/employee-edit`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const employeeDetails = async (params) => {
  try {
    const res = await apiRequest("get", `/user/${params?.id}`);

    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteEmployee = async (payload = {}) => {
  try {
    const res = await apiRequest("PATCH", `/user/delete-all`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
