import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { formValidate } from "../../../helpers/formValidate";
import ImageUploader from "../../../components/form/ImageUploader";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { setTitle } from "../../../helpers/MetaTag";
import {
  profileUpdate,
  profileDetails,
} from "../../../services/ProfileService";
import ChangePassword from "../change-password/changePassword";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { Validation } from "../../../helpers/Validation";

const EditProfile = () => {
  setTitle("ORG Chart Admin | Profile");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [details, setDetails] = useState({});

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    profileDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        dispatch(setProfile(res?.data));
      } else {
        toast.error("Something went wrong!");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (details) {
      setFieldsValue({
        firstName: details?.firstName || "",
        lastName: details?.lastName || "",
        email: details?.email || "",
        phone: details?.phone || "",
      });
      if (details?.image?.url) {
        setImage(details?.image?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);
  const validPhoneNumber = (number) => {
    const errors = [];
    if (number.length < 10) {
      errors.push("Phone number must be exactly 10 digits.");
    }
    if (number.length > 10) {
      errors.push("Phone number must be exactly 10 digits.");
    }
    return errors;
  };
  const onSubmit = (values) => {
    setLoading(true);
    if (!Validation("email", values.email)) {
      toast.error("Invalid email format. Please enter a valid email.");
      setLoading(false);
      return;
    }
    if (values.phone) {
      const phoneNumberErrors = validPhoneNumber(values.phone);
      if (phoneNumberErrors.length > 0) {
        toast.error(phoneNumberErrors.join(" "));
        setLoading(false);
        return;
      }
    }
    profileUpdate({
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: values?.phone,
      image: image || "null",
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        dispatch(setProfile(res?.data));
      }
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">Profile</div>
      </div>
      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="image bg-white p-4 col-span-12 lg:col-span-3 border-r border-slate-200 h-auto">
            <ImageUploader
              label=""
              labelClasses="text-lg font-bold"
              isMultiple={false}
              image={image}
              setImage={setImage}
              inputClasses="h-48 w-48"
              buttonLabel={image ? "Change image" : "Upload image"}
            />
          </div>
          <div className="input bg-white col-span-12 lg:col-span-9 ">
            <div className="text-xl font-bold mb-3">Profile Info</div>
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative">
                <Input
                  label={"FirstName"}
                  inputType={"text"}
                  value={values?.firstName}
                  inputPlaceholder={"Enter firstname..."}
                  inputName="firstName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "firstName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"LastName"}
                  inputType={"text"}
                  value={values?.lastName}
                  inputPlaceholder={"Enter lastname..."}
                  inputName="lastName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "lastName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Email"}
                  inputType={"text"}
                  value={values?.email}
                  inputPlaceholder={"Enter email..."}
                  inputName="email"
                  onChange={handleChange}
                  isValidate={true}
                  isDisabled={true}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Phone"}
                  inputType={"number"}
                  value={values?.phone}
                  inputPlaceholder={"Enter phone..."}
                  inputName="phone"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div
                className="flex justify-end items-center"
                data-tooltip-id="edit-profile"
                data-tooltip-place="left"
              >
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel="Save"
                  loading={loading}
                />
                <Tooltip
                  id="edit-profile"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      Click to save profile details.
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ChangePassword />
    </div>
  );
};

export default EditProfile;
