import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { getPositionList } from "../../../../services/adminService/CompanyService";
import { createFilter, handleFilterChange } from "../../../../helpers";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "../../../../components/form/Select";
import Search from "../../../../components/form/Search";
import Button from "../../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Table from "../../../../components/elements/table/Table";
import DeleteModal from "../../../../components/common/DeleteModal";
import { status_filter } from "../../../../components/constant/constant";
import { setTitle } from "../../../../helpers/MetaTag";

const CompanyPosition = ({ id }) => {
  const navigate = useNavigate();
  setTitle("ORG Chart Admin | Companies");
  const [selectedItems, setSelectedItems] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    limit: 5,
    offset: 0,
    currentPage: 0,
    keyword: "",
    status: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  // loading the company list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      getPositionList({
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        companyId: id,
        ...createFilter(filters?.status, "status"),
      }).then((res) => {
        if (res && res.status === 200) {
          setList({
            loading: false,
            data: res.docs,
            pageCount: res.totalPages,
            totalItem: res.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    loadList();
  }, [loadList]);


  const tableHeadData = [
    {
      _id: 1,
      name: "Title",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-title");
        } else {
          setSort("title");
        }
      },
    },
    {
      _id: 2,
      name: "Short Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-short_name");
        } else {
          setSort("short_name");
        }
      },
    },
    {
      _id: 3,
      name: "Description",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-description");
        } else {
          setSort("description");
        }
      },
    },
    {
      _id: 4,
      name: "Created Date",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0 }));
      },
    },
  ];
  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "text-tooltip",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.title || "N/A",
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.title && (
                <h4>
                  <span className="text-slate-200">Title: </span>
                  {item?.title}
                </h4>
              )}
              {item?.short_name && (
                <h4>
                  <span className="text-slate-200">Short Name: </span>
                  {item?.short_name}
                </h4>
              )}
            </div>
          </>
        ),
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={
                  "text-xs font-medium leading-tight rounded py-1 px-2 bg-sky-100 text-sky-600"
                }
              >
                {item?.short_name || "N/A"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
       data: (item?.description?.split(' ').length > 20 ? item?.description.split(' ').slice(0, 10).join(' ') + '...' : item?.description) || "N/A"

      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt
          ? moment(item?.createdAt).format("MM-DD-YYYY")
          : "N/A",
      },

      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5 flex-shrink-0">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Company Positions
        </div>

        <div className="flex items-center gap-3">
          <div className="w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) =>
                handleFilterChange("limit", val, setFilters)
              }
            />
          </div>
          <div className="w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
              }}
            />
          </div>
        </div>
      </div>
      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        // containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        // deleteHandler={deletefunction}
      />
    </div>
  );
};

export default CompanyPosition;
