import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerInput({
  dateFormat,
  startDate,
  setStartDate,
  type = "calendar", // input || calendar
}) {
  const handleInputChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className="relative">
      {type === "input" ? (
        <DatePicker
          selected={startDate}
          dateFormat={dateFormat}
          onChange={handleInputChange}
          className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      ) : (
        <DatePicker
          inline
          selected={startDate}
          dateFormat={dateFormat}
          calendarClassName="custom-calendar"
          onChange={handleInputChange}
          className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      )}
    </div>
  );
}
