import React, { useState } from "react";
import NavigationItem from "./NavigationItem";
import { classNames } from "../../helpers/classNames";

const SidebarBlock = ({ title, data, selected = false }) => {
  const [isBlockOpen, setBlockOpen] = useState(true);
  return (
    <>
      <div className="relative py-2 space-y-2">
        <button
          type="button"
          className="group w-full flex items-center justify-between py-2"
          onClick={() => setBlockOpen(!isBlockOpen)}
        >
          <div className="text-xs font-medium text-slate-500 uppercase">
            {title}
          </div>
          <div className="pt-0.5 text-sm text-slate-400 !leading-none transition-all duration-200 rounded w-5 aspect-square flex items-center justify-center border border-transparent group-hover:bg-orange-100 group-hover:text-orange-400">
            <i
              className={classNames(
                "fa-regular fa-fw fa-angle-down transition-all duration-200",
                isBlockOpen ? "rotate-0" : "-rotate-90"
              )}
            ></i>
          </div>
        </button>
        {data.length > 0 && (
          <div
            className={classNames(
              "relative -mx-4 px-4 space-y-1 transition-all duration-200 overflow-hidden !mt-0",
              isBlockOpen
                ? "max-h-[2000px] opacity-100 visible"
                : "max-h-0 opacity-0 invisible"
            )}
          >
            {data.map((item, index) => (
              <div key={index} className="-mx-2 relative">
                <NavigationItem data={item} selected={selected} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarBlock;
