import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import UserImage from "../../assets/images/member-01.webp";
import Image from "../../components/elements/Image";
import { classNames } from "../../helpers/classNames";
import LogoutModal from "../../components/common/LogoutModal";

const UserDropdown = ({
  role,
  data,
  isFront,
  xPlacement,
  dropdownContainer,
  ...props
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const dropdownData = isFront === true 
  ? [
      {
        _id: 1,
        name: "Profile",
        icon: "fa-regular fa-circle-user",
        link: "/profile",
        standout: false,
      },
      {
        _id: 2,
        name: "Change Password", 
        icon: "fa-regular fa-gear",
        link: "/change-password", 
        standout: false,
      },
      {
        _id: 3,
        name: "Logout",
        icon: "fa-regular fa-arrow-right-from-arc",
        onclick: (val) => {
          setOpen(true);
        },
        standout: true,
      },
    ]
  : [
      {
        _id: 1,
        name: "Profile",
        icon: "fa-regular fa-circle-user",
        link: "/admin/profile",
        standout: false,
      },
      {
        _id: 2,
        name: "Settings",
        icon: "fa-regular fa-gear",
        link: "/admin/site-settings",
        standout: false,
      },
      {
        _id: 3,
        name: "Logout",
        icon: "fa-regular fa-arrow-right-from-arc",
        onclick: (val) => {
          setOpen(true);
        },
        standout: true,
      },
    ];


  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="flex-shrink-0 rounded-full overflow-hidden">
                  {!data?.image ? (
                    <div className="flex items-center justify-center  bg-orange-300 rounded-full w-8 h-8">
                      <i className="fa-regular fa-user" />
                    </div>
                  ) : (
                    <Image
                      src={data?.image ? data?.image : UserImage}
                      effect={"blur"}
                      alt={"Administrator"}
                      className={"!w-8 !h-8"}
                    />
                  )}
                </div>
                <div
                  className={`text-sm font-medium mx-2 capitalize ${
                    role === "admin" ? "text-black" : "text-white"
                  }`}
                >
                  {data?.name && data?.name}
                </div>
                <div className="text-sm text-slate-300">
                  <i
                    className={classNames(
                      "fa-regular fa-fw fa-chevron-down transition-all duration-200",
                      role === "admin" ? "text-black" : "text-white",
                      open ? "-rotate-180" : ""
                    )}
                  ></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                className={classNames(
                  "absolute border border-slate-200 w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                  dropdownContainer
                )}
              >
                <div className="py-1 max-h-80 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item) => (
                    <Menu.Item key={item._id}>
                      {({ active }) =>
                        item?.link ? (
                          <Link
                            to={item?.link}
                            className={classNames(
                              "group flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-orange-100 text-orange-500"
                                : "text-slate-500",
                              item.standout
                                ? "!text-opplio-red hover:!bg-opplio-red/10"
                                : "",
                              item.class
                            )}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </Link>
                        ) : (
                          <div
                            onClick={item?.onclick}
                            className={classNames(
                              "group cursor-pointer flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-orange-100 text-orange-500"
                                : "text-slate-500",
                              item.standout
                                ? "!text-red-500 hover:!bg-red-500/10"
                                : "",
                              item.class
                            )}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </div>
                        )
                      }
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default UserDropdown;
