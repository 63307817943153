import DepartmentMange from "../../../modals/front/DepartmentMange";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import EmpImage1 from "../../../../assets/images/member-03.webp";
import React, { useCallback, useEffect, useState } from "react";
import DeparmentLoader from "../../../loaders/DeparmentLoader";
import NoDataFound from "../../../common/NodataFound";
import DeleteModal from "../../../common/DeleteModal";
import Pagination from "../../../common/Pagination";
import Image from "../../../elements/Image";
import Search from "../../../form/Search";
import { useDispatch } from "react-redux";
import Button from "../../../form/Button";
import { toast } from "react-toastify";
import {
  deleteDepartment,
  getDepartmentList,
} from "../../../../services/frontService/DepartmentService";
import Directory from "../../../../pages/front/directory/Directory";

export default function DepartmentSection() {
  const dispatch = useDispatch();
  const [isDepModal, setDepModal] = useState({ data: {}, isOpen: false });
  const [keyword, setKeyword] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [filters, setFilters] = useState({
    limit: 8,
    offset: 0,
    currentPage: 0,
  });

  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    getDepartmentList({ keyword, ...filters }).then((res) => {
      if (res && res.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [keyword, filters]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const departmentList = list?.data?.map((dep) => ({
    _id: dep?._id,
    empCount: dep?.departmentEmpCount,
    name: dep?.name,
    image: EmpImage1,
  }));

  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteDepartment({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          return {
            ...pre,
            data: updatedData,
          };
        });
        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount: pre?.departmentCount - 1,
          }))
        );
        setDepModal((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  const borderColors = [
    "border-t-blue-500",
    "border-t-green-500",
    "border-t-purple-500",
    "border-t-red-500",
    "border-t-yellow-500",
    "border-t-indigo-500",
  ];

  return (
    <Directory>
      <div className="!h-full flex flex-col">
        <div className="w-full flex  flex-col lg:flex-row space-y-2 lg:items-center justify-between">
          <p className="text-lg text-gray-800 pl-2">
            Departments
            <span className="text-[#f76310]"> ({list?.totalItem})</span>
          </p>
          <div className="flex gap-2 items-center justify-center ">
            <Search
              placeholder={"Search here"}
              search={(val) => setKeyword(val)}
              divClasses={"!rounded-md !h-10 border border-slate-300"}
            />
            <Button
              buttonLabel={"New"}
              buttonIcon={"fa-regular fa-plus text-xs"}
              buttonClasses={"!w-20"}
              buttonIconPosition={"left"}
              buttonFunction={() => {
                setDepModal({ data: {}, isOpen: true });
              }}
            />
          </div>
        </div>

        <div className="w-full grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-2 py-2 px-1 gap-4">
          {list?.loading ? (
            <DeparmentLoader count={8} />
          ) : (
            <>
              {list?.data &&
              Array.isArray(list?.data) &&
              list.data.length === 0 ? (
                <div className="col-span-4">
                  <NoDataFound title="No departments found" />
                </div>
              ) : (
                departmentList?.map((item, index) => (
                  <div
                    key={item?._id}
                    className={`relative flex flex-col justify-between col-span-1 p-2 px-3 border rounded-lg shadow hover:shadow-md cursor-pointer ${
                      borderColors[index % borderColors.length]
                    } border-t-4`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="xl:text-base 2xl:text-lg text-gray-800 font-medium  uppercase">
                        {item?.name?.substring(0, 20) +
                          (item?.name?.length > 20 ? "....." : "")}
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-8">
                      <div className="flex items-center justify-end gap-1">
                        <i className="fa-regular fa-user text-sm" />
                        <p className="font-medium text-slate-500">
                          {item?.empCount}
                        </p>
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          buttonIcon={
                            "fa-fw fa-regular fa-trash-can text-xs font-light"
                          }
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "w-6 h-6 !border !bg-slate-50 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
                          }
                          buttonFunction={() => {
                            setDeleteModal((pre) => ({
                              ...pre,
                              isOpen: true,
                              ids: [item?._id],
                            }));
                          }}
                        />
                        <Button
                          buttonIcon={
                            "fa-fw fa-regular fa-pen-to-square text-xs font-light"
                          }
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
                          }
                          buttonFunction={() => {
                            setDepModal({ data: item, isOpen: true });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>

        {list?.pageCount > 1 && (
          <div className="realtive mt-auto">
            <Pagination
              paginationClasses={"mt-4 justify-center"}
              onPageChange={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  offset: pre?.limit * val?.selected,
                  currentPage: val?.selected,
                }));
              }}
              pageCount={list?.pageCount}
              currentPage={filters?.currentPage}
            />
          </div>
        )}

        <DepartmentMange
          setList={setList}
          isDepModal={isDepModal}
          setDepModal={setDepModal}
        />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deletefunction}
          title="Delete Department"
          subTitle="You are trying to delete a department"
        />
      </div>
    </Directory>
  );
}
