import Image from "../../../elements/Image";
import EmpImage from "../../../../assets/images/member-02.webp";

export default function Avaiability() {
  return (
    <div className="p-2">
      <p className="text-xl font-semibold text-slate-600 mb-4">Availability</p>
      <div className="border border-slate-300 rounded-lg p-4 bg-white shadow-sm">
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1 ">
            <p className="font-semibold text-slate-600">Today</p>
            <div className="flex flex-col space-y-2 py-2">
              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 ">
            <p className="font-semibold text-slate-600">Tomorrow</p>
            <div className="flex flex-col space-y-2 py-2">
              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4 px-4 py-2 bg-white border border-slate-300 rounded-lg shadow-sm">
                <div className="w-12 h-w-12 relative">
                  <Image
                    src={EmpImage}
                    alt={""}
                    effect={"blur"}
                    className="w-full h-full object-cover rounded-full border"
                  />
                  <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                    <i className="fa-solid fa-cake-candles" />
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-slate-700">
                    Work Anniversary
                  </p>
                  <p className="text-xs text-slate-500">
                    17 Jul • Celebrating 15 years!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
