import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import Logo from "../../assets/images/logo.png";
import UserDropdown from "./UserDropdown";
import Search from "../../components/form/Search";
import Image from "../../components/elements/Image";
import InviteDropdown from "./InviteDropdown";
import DeleteModal from "../../components/common/DeleteModal";
import { Tooltip } from "react-tooltip";

const Header = ({ profile, isMenuOpen, setMenuOpen }) => {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    loading: false,
  });
  const data = {
    image: profile?.image?.url,
    name: profile?.firstName
      ? profile?.firstName + " " + profile?.lastName
      : null,
  };
  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("adminData");
    if (dataFromLocalStorage) {
      const parsedData = JSON.parse(dataFromLocalStorage);
      setAdminData(parsedData);
    }
  }, []);
  const onSubmit = async (adminData) => {
    setDeleteModal({
      ...deleteModal,
      loading: true,
    });
    if (adminData?._id && adminData?.uuid) {
      localStorage.setItem("auth_token", adminData?.authToken);
      localStorage.setItem("role", "ADMIN");
      navigate("/admin/dashboard");
      setDeleteModal({
        isOpen: false,
        loading: false,
      });
    }
  };
  const handleModalOpen = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: true,
    });
  };

  const handleModalClose = () => {
    setDeleteModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  return (
    <>
      <header className="relative w-full bg-slate-900 shadow flex items-center px-6">
        <div className="mr-auto flex items-center gap-10">
          <div className="flex items-center gap-4">
            {/* <Button
              buttonHasLink={false}
              buttonIconPosition={'left'}
              buttonIcon={'fa-solid fa-sharp fa-grid-round'}
              buttonClasses={'!px-0 w-10 !rounded-lg !text-2xl !bg-white !text-opplio-graySky !border-opplio-line !shadow !flex !items-center !justify-center hover:!shadow-md'}
              buttonFunction={() => setMenuOpen(!isMenuOpen)}
            /> */}
            <Link to="/" className="h-8">
              <Image
                src={Logo}
                alt={"Logo"}
                effect={"blur"}
                className={"!w-auto !h-8 !rounded-none"}
              />
            </Link>
          </div>
          
          {adminData?.login === "Administrator-login" && (
            <div
            className="relative"
            data-tooltip-id="back-dashboard"
            data-tooltip-place="bottom"
          >
            <Button
                buttonHasLink={false}
                buttonIcon={"fa-regular fa-arrow-left-to-bracket"}
                buttonIconPosition={"left"}
                buttonLabel={"Back to Admin"}
                buttonFunction={handleModalOpen}
              />
            <Tooltip
              id="back-dashboard"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Please Click for back to admin dashboard.</div>
              )}
            />
          </div>
          )}
        </div>
        <div className="ml-auto">
          <div className="flex items-center gap-4">
            <div className="relative w-80">
              <Search
                placeholder={"Search"}
                divClasses={"!bg-slate-100 !h-8"}
                inputClasses={"!h-8"}
                iconClasses={"!w-8 !h-8"}
              />
            </div>
            <div className="relative">
              <Button
                buttonHasLink={false}
                buttonIcon={"fa-regular fa-bars"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!p-0 !aspect-square flex items-center justify-center !rounded-full !text-xl"
                }
                buttonFunction={() => setMenuOpen(!isMenuOpen)}
              />
            </div>
            <div className="relative border-x border-gray-700">
              <InviteDropdown xPlacement={"bottomRight"} />
            </div>
            <div className="relative">
              <UserDropdown
                data={data}
                role="user"
                xPlacement={"bottomRight"}
                isFront={true}
                dropdownContainer="w-44"
              />
            </div>
          </div>
        </div>
      </header>
      <DeleteModal
        open={deleteModal.isOpen}
        onClose={handleModalClose}
        deleteHandler={() => onSubmit(adminData)}
        title="Switch Dashboard"
        loading={deleteModal.loading}
        deleteButtonLabel="Yes, Go Back"
        deleteButtonIcon ={"fa-regular fa-arrow-left-to-bracket"}
        customMessage="Are you sure that you want to switch to the admin dashboard?"
      />
    </>
  );
};

export default Header;
