import React from 'react';
import { classNames } from '../../helpers/classNames';

const Radio = ({
  radioName,
  radioInputClass,
  isChecked,
  radioLabel,
  radioLableClass,
  radioCheckClass,
  radioValue,
  divClass,
  onChange = () => { },
  ...props
}) => {
  return (
    <>
      <div className={classNames("relative overflow-hidden flex items-center", divClass)}>
        <input type="radio"
          name={radioName}
          className={classNames("peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer", radioInputClass)}
          defaultChecked={isChecked}
          value={radioValue}
          onChange={onChange}
        />
        <div className={classNames("w-5 h-5 flex-shrink-0 border border-opplio-border rounded-full flex items-center justify-center text-[8px] bg-white text-black/0 peer-checked:bg-opplio-blue peer-checked:border-carbg-opplio-blue peer-checked:text-white", radioCheckClass)}>
          <i className="fa-solid fa-fw fa-circle"></i>
        </div>
        {radioLabel && <div className={classNames("text-sm text-opplio-gray w-full ml-3 transition-all duration-200", radioLableClass)}>{radioLabel}</div>}
      </div>
    </>
  );
};

export default Radio;