import React, { useState } from "react";
import { classNames } from "../../helpers/classNames";

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Search = ({
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  isDebounce,
  iconPosition = "left",
  searchValue,
  icon = "fa-light fa-magnifying-glass",
  search = () => {},
  onKeyDown = () => {},
}) => {
  const [isFocused, setFocused] = useState(false);
  const debouncedSearch = debounce(search, 500);

  const handleSearchChange = (e) => {
    if (!isDebounce) {
      debouncedSearch(e.target.value);
    } else {
      search(e.target.value);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "relative flex items-center h-10 w-full bg-white rounded-md overflow-hidden border transition-all duration-200",
          isFocused ? "border-orange-400" : "border-orange-300",
          divClasses
        )}
      >
        {iconPosition === "left" && (
          <div
            className={classNames(
              "w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-opplio-grayB rounded-md",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
        <input
          type="text"
          placeholder={placeholder}
          className={classNames(
            "flex-shrink !border-0 w-full !ring-0 bg-transparent text-slate-800 placeholder:text-slate-500 placeholder:font-medium text-sm p-0 px-3 font-normal  focu",
            inputClasses,
            iconPosition === "left"
              ? "!pl-0"
              : iconPosition === "right"
              ? "!pr-0"
              : ""
          )}
          value={searchValue}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          // onChange={(e) => debouncedSearch(e.target.value)}
          onChange={handleSearchChange}
          onKeyDown={onKeyDown}
        />
        {iconPosition === "right" && (
          <div
            className={classNames(
              "w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-opplio-grayB rounded-md",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
