import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ImageUploader from "../../components/form/ImageUploader";

const ProfileSidebar = ({
  image,
  selectedOption,
  setSelectedOption = () => {},
  setImage = () => {},
}) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile?.data);
  const handleClick = (option) => {
    setSelectedOption(option);
    navigate(`/${option}`);
  };

  const getBackgroundClass = (option) => {
    return selectedOption === option
      ? "bg-orange-600 text-white"
      : "bg-slate-100 text-black hover:!border-orange-600 hover:shadow-md hover:text-orange-600";
  };

  return (
    <>
      <div className="mt-10">
        <div className="relative flex items-end  justify-center">
          <div className="">
            <ImageUploader
              label=""
              labelClasses="text-lg font-bold"
              isMultiple={false}
              image={image}
              setImage={setImage}
              isCameraIcon={true}
              buttonLabel={image ? "Change Logo" : "Upload logo"}
              buttonClasses="!bg-transparent !border border-orange-500 !text-orange-500 !h-8"
              inputClasses="w-40 h-40"
            />
          </div>
        </div>
        <div className="text-center pt-4 pb-2">
          <div className="text-xl font-bold text-orange-600">Hello!</div>
          <div className="text-black text-2xl font-semibold mt-1">
            {profile?.firstName + " " + profile?.lastName}
          </div>

          <div className="text-sm font-semibold pt-1">
            Welcome to Your Profile
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex flex-col gap-3">
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "profile"
            )} cursor-pointer`}
            onClick={() => handleClick("profile")}
          >
            <i className="fa-regular fa-circle-info"></i>
            <span className="pl-3">My Profile</span>
          </div>
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "change-password"
            )} cursor-pointer`}
            onClick={() => handleClick("change-password")}
          >
            <i class="fa-regular fa-key"></i>
            <span className="pl-3">Change Password</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSidebar;
