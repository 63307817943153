import React from "react";
import Button from "../form/Button";
import Modal from "../elements/Modal";

const DeleteModal = ({
  open = false,
  onClose = () => {},
  deleteHandler = () => {},
  title = "Delete Record",
  subTitle = "You are trying to delete the record",
  loading = false,
  customMessage,
  deleteButtonLabel = "Delete",
  deleteButtonIcon,
}) => {
  return (
    <Modal open={open} onClose={() => onClose()} title={title} size="sm">
      <div className="text-center my-3 text-slate-500">
        {customMessage ? (
          customMessage
        ) : (
          <>
            {subTitle},
            <br /> click &quot;{deleteButtonLabel}&quot; below.
          </>
        )}
      </div>
      <div className="flex gap-6 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={() => onClose()}
          buttonClasses={"bg-slate-50 border-red-500 !text-slate-500"}
        />
        <Button
          buttonFunction={deleteHandler}
          buttonIconPosition={"left"}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : deleteButtonIcon || "fa-regular fa-trash-can"
          }
          buttonLabel={deleteButtonLabel || "Delete"}
          buttonClasses={"bg-red-500 border-red-500 hover:bg-red-600"}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
