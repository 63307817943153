import React, { useEffect } from "react";
import Button from "../../../components/form/Button";
import Modal from "../../../components/elements/Modal";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { toast } from "react-toastify";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import Input from "../../form/Input";
import { formValidate } from "../../../helpers/formValidate";

const OurMissionManage = ({
  ourMissionModal,
  setMissionModal = () => {},
  setDetails = () => {},
}) => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, Validation);

  const data = ourMissionModal?.data;

  useEffect(() => {
    if (data) {
      setFieldsValue({
        goal: data?.goal || "",
        description: data?.description || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    companyUpdate({
      companyGoal: values?.goal,
      companyDescription: values?.description,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        setMissionModal({ isOpen: false });
      } else {
        toast.error(res?.data?.message);
        setMissionModal({ isOpen: false });
      }
    });
  };

  return (
    <div>
      <Modal
        title={data?.goal}
        size="lg"
        open={ourMissionModal.isOpen}
        onClose={() => setMissionModal({ isOpen: false })}
        footer={null}
        modaltitleClasses="capitalize"
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="relative col-span-12">
            <Input
              label={"Heading"}
              inputPlaceholder={"Enter section heading..."}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.goal}
              onChange={handleChange}
              inputName={"goal"}
              charLimit={200}
              {...formValidate(errors, "goal")}
            />
          </div>
          <div className="relative col-span-12 mt-1">
            <TextAreaAutoSize
              label={"Description"}
              inputValue={values?.description}
              inputName={"description"}
              inputPlaceholder="Enter description ..."
              onChange={handleChange}
            />
          </div>

          <div className="flex items-center justify-end mt-4 gap-4">
            <Button
              buttonLabel={"Cancel"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-transparent !border-orange-500 !text-orange-500"
              }
              buttonFunction={() => setMissionModal({ isOpen: false })}
            />
            <Button
              buttonLabel={"Save"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonType="submit"
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default OurMissionManage;
