import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmpImage from "../../../../assets/images/member-02.webp";
import Image from "../../../elements/Image";

export default function Celebrations() {
  const celebrations = [
    {
      title: "Happy Birthday!",
      date: "Jul 10",
      image: EmpImage,
      name: "Avik Biswas",
      position: "Backend Developer",
    },
    {
      title: "Happy Birthday!",
      date: "Jul 10",
      image: EmpImage,
      name: "Subhajit Das",
      position: "Frontend Developer",
    },
    {
      title: "Happy Birthday!",
      date: "Jul 10",
      image: EmpImage,
      name: "Trayee Ghosh",
      position: "Frontend Developer",
    },
    {
      title: "Happy Birthday!",
      date: "Jul 10",
      image: EmpImage,
      name: "Bikram Das",
      position: "Designer",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    centerPadding: "0",
  };

  return (
    <div className="px-2 py-1 relative">
      <p className="text-xl font-semibold text-slate-600 mb-6">Celebrations</p>
      <div className="absolute top-13 right-0 w-full h-56 bg-gradient-to-l from-white via-transparent   z-10 pointer-events-none"></div>
      <Slider {...sliderSettings} className="w-full cursor-grab relative ">
        {celebrations.map((celebration, index) => (
          <div key={index}>
            <div className="h-52 mx-2 bg-white border border-slate-300 rounded-lg shadow-sm p-2 flex flex-col items-center gap-2 py-5">
              <p className="font-semibold text-slate-700">
                {celebration.title} | {celebration.date}
              </p>
              <div className="w-16 h-w-16 relative">
                <Image
                  src={celebration.image}
                  alt={celebration.name}
                  effect={"blur"}
                  className="w-full h-full object-cover rounded-full border"
                />
                <span className="absolute bottom-1 -right-2 text-sm text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                  <i className="fa-solid fa-cake-candles" />
                </span>
              </div>
              <div className="text-center">
                <p className="text-lg font-medium text-slate-600 ">
                  {celebration.name}
                </p>
                <p className="text-sm text-slate-500">{celebration.position}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
