import React, { useState, useRef, useEffect, Fragment } from "react";
import NoData from "../../assets/images/no-results-bg.2d2c6ee3.png";
import { bottom, top, left, right } from "@popperjs/core";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { usePopper } from "react-popper";
import Search from "./Search";
import Image from "../elements/Image";

export default function SelectDropdownSearch({
  buttonArrowIcon = "fa-regular fa-chevron-down",
  buttonArrowIconClasses,
  dropdownButtonClass,
  dropdownButtonStyle,
  onSearch = () => {},
  onChange = () => {},
  dropdownData = [],
  loading = false,
  dropdownClass,
  labelClasses,
  xPlacement,
  selectName,
  value,
  label,
  errorText,
  errorType,
  isSearch,
  tabTextColor,
  placeholder,
  ...props
}) {
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [list, setList] = useState(dropdownData);
  const [selected, setSelected] = useState();
  const popperElRef = useRef(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  useEffect(() => {
    setList(dropdownData);
    if (value) {
      setSelected(dropdownData.find((item) => item._id === value));
    }
  }, [dropdownData, value]);

  const onSearchHandler = (e) => {
    if (e.key === " " || e.keyCode === 32) {
      e.preventDefault();
    }
    onSearch(e);
    setList(dropdownData.filter((item) => item.name.toLowerCase().includes(e)));
  };

  const handleChange = (e) => {
    const newValue = e._id || e.value;
    if (
      selected &&
      (selected._id === newValue || selected.value === newValue)
    ) {
      onChange({ target: { name: selectName, value: null } });
      setSelected(null);
    } else {
      onChange({ target: { name: selectName, value: newValue } });
      setSelected(e);
    }
  };

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-medium text-slate-500 mb-1",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <Listbox
          value={selected}
          onChange={handleChange}
          as="div"
          className="relative"
        >
          {({ open }) => (
            <>
              <Listbox.Button
                ref={setTargetElement}
                className="flex items-center w-full"
              >
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between gap-1 pl-3 pr-6 rounded-md border text-sm font-medium bg-white border-slate-200 text-slate-600",
                    dropdownButtonClass
                  )}
                  style={dropdownButtonStyle}
                >
                  <div
                    className={
                      selected?.name
                        ? "text-sm text-slate-600"
                        : "text-sm font-normal text-slate-500"
                    }
                  >
                    {selected?.name ? selected.name : placeholder}
                  </div>
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-2 -translate-y-1/2 transition-all duration-200 text-xs",
                        open ? "-rotate-180" : "",
                        buttonArrowIconClasses,
                        tabTextColor ? "" : "text-white"
                      )}
                      style={tabTextColor ? { color: tabTextColor } : {}}
                    >
                      {loading ? (
                        <i
                          className={
                            "fa-duotone fa-spinner-third animate-spin text-sm"
                          }
                        />
                      ) : (
                        <i className={classNames("fa-fw", buttonArrowIcon)} />
                      )}
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                className="z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <Listbox.Options
                  className={classNames(
                    "absolute mt-1 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                    dropdownClass
                  )}
                >
                  {isSearch && (
                    <div className="py-1 px-2 border-b border-slate-100">
                      <Search
                        isDebounce={true}
                        placeholder="Search"
                        search={onSearchHandler}
                      />
                    </div>
                  )}
                  <div className="py-1 max-h-60 overflow-auto scrollbar">
                    {list?.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                            active
                              ? "bg-slate-200 text-black"
                              : value?._id === item._id
                              ? "bg-green-100 text-slate-800"
                              : "text-black",
                            item.isDisabled
                              ? "!bg-slate-100 !cursor-not-allowed"
                              : "",
                            item.class
                          )
                        }
                        value={item}
                        disabled={item.isDisabled}
                      >
                        {({ selected }) => (
                          <div className="flex gap-2">
                            {item?.url && (
                              <div className="flex items-center justify-center w-12 h-12 rounded-full overflow-hidden border">
                                <Image
                                  effect="blur"
                                  src={item.url}
                                  alt=""
                                  className="w-full h-full object-cover"
                                />
                              </div>
                            )}
                            <div className="flex flex-col items-start justify-center">
                              <div className="flex items-center gap-1 text-sm font-medium text-slate-800">
                                {item.name}
                                {item.icon && (
                                  <i
                                    className={classNames(item.icon, "text-xs")}
                                  />
                                )}
                              </div>
                              {item.subItem && (
                                <div className="text-xs text-slate-400">
                                  {item.subItem}
                                </div>
                              )}
                            </div>
                            {selected && (
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-slate-800 text-base">
                                <i className="fa-regular fa-fw fa-check"></i>
                              </div>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                    {list?.length == 0 && !loading && (
                      <div className="flex flex-col items-center justify-center w-full py-3">
                        <div
                          className={classNames(
                            "relative w-1/2 overflow-hidden"
                          )}
                        >
                          <Image
                            src={NoData}
                            alt="no-data"
                            className="w-full h-full "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
      {errorType && errorText && (
        <div
          className={`text-xs mt-1 ${
            errorType === "danger" ? "text-red-600" : "text-amber-600"
          }`}
        >
          <i
            className={`fa-regular fa-fw ${
              errorType === "danger"
                ? "fa-square-exclamation"
                : "fa-triangle-exclamation"
            } text-sm mr-1`}
          ></i>
          {errorText}
        </div>
      )}
    </>
  );
}
