import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import {
  employeeAdd,
  employeeEdit,
} from "../../../services/frontService/EmployeeService";
import ImageUploader from "../../form/ImageUploader";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";

export default function EmployeeManage({
  setList,
  dep_list,
  pos_list,
  isEmployeeModal,
  setEmployeeModal = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");

  const data = isEmployeeModal?.data;
  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email !" },
    phone: { required: true, message: "Please enter  phone !" },
    positionId: { required: true, message: "Please select a position !" },
    departmentId: { required: true, message: "Please select a department !" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        positionId: data?.positionDetails?._id,
        departmentId: data?.departmentDetails?._id,
      });
      setImage(data?.image?.url);
    }
  }, [data]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        image: image ? image : "null",
      };

      const response = data?._id
        ? await employeeEdit({
            ...payload,
            id: data?._id,
          })
        : await employeeAdd(payload);

      setLoading(false);

      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setEmployeeModal((pre) => ({ ...pre, isOpen: false }));
        setList((prev) => ({
          ...prev,
          data: prev?.data
            ?.map((item) =>
              item?._id === response?.data?._id ? response?.data : item
            )
            .concat(
              !prev?.data?.some((item) => item?._id === response?.data?._id)
                ? [response?.data]
                : []
            ),
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount:
              response?.status === 201
                ? pre?.employeeCount + 1
                : pre?.employeeCount,
          }))
        );
      } else {
        toast.error(response.message || response?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl"
      open={isEmployeeModal?.isOpen}
      onClose={() => {
        setEmployeeModal((pre) => ({ ...pre, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-2 gap-2"
      >
        <div className="col-span-1 flex flex-col space-y-3 mt-8">
          <div className="relative ">
            <ImageUploader
              label=""
              image={image}
              altImage={noEmpImg}
              isMultiple={false}
              setImage={setImage}
              isCameraIcon={true}
              buttonLabelClasses="!text-xs"
              buttonIcon="fa-upload text-xs"
              labelClasses="text-lg font-bold"
              buttonClasses="!px-0 !h-8 !cursor-pointer"
              inputClasses="w-28 h-28 !hover:bg-slate-300"
              buttonLabel={data?.image ? "Change image" : "Upload image"}
            />
          </div>
          <div className="relative mt-4">
            <SelectDropdownSearch
              label={"Position"}
              placeholder={"Select an position"}
              isSearch={true}
              xPlacement={"bottom"}
              buttonArrowIcon={"fa-solid fa-chevron-down"}
              tabTextColor={"text-slate-700"}
              selectName={"positionId"}
              loading={pos_list?.loading}
              value={values?.positionId}
              onChange={handleChange}
              dropdownButtonClass="!px-3 !border-gray-200 !w-full"
              {...formValidate(errors, "positionId")}
              dropdownData={pos_list?.data?.map(({ _id, title }) => ({
                name: title,
                _id: _id,
              }))}
              {...formValidate(errors, "positionId")}
            />
          </div>
          <div className="relative mt-1">
            <SelectDropdownSearch
              label={"Department"}
              placeholder={"Select an department"}
              isSearch={true}
              xPlacement={"bottom"}
              buttonArrowIcon={"fa-solid fa-chevron-down"}
              tabTextColor={"text-slate-700"}
              selectName={"departmentId"}
              loading={dep_list?.loading}
              value={values?.departmentId}
              onChange={handleChange}
              dropdownButtonClass="!px-3 !border-gray-200 !w-full"
              {...formValidate(errors, "departmentId")}
              dropdownData={dep_list?.data?.map(({ _id, name }) => ({
                name: name,
                _id: _id,
              }))}
            />
          </div>
        </div>
        <div className="col-span-1 flex flex-col space-y-3 mt-1">
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"First Name"}
              inputPlaceholder={"Enter first name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.firstName}
              inputName={"firstName"}
              onChange={handleChange}
              {...formValidate(errors, "firstName")}
            />
          </div>
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"Last Name"}
              inputPlaceholder={"Enter last name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.lastName}
              inputName={"lastName"}
              onChange={handleChange}
              {...formValidate(errors, "lastName")}
            />
          </div>
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-envelope text-slate-400"}
              inputGroupPosition={"left"}
              label={"Email"}
              inputPlaceholder={"Enter email"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"email"}
              value={values.email}
              inputName={"email"}
              onChange={handleChange}
              {...formValidate(errors, "email")}
            />
          </div>
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-phone text-slate-400"}
              inputGroupPosition={"left"}
              label={"Phone"}
              inputPlaceholder={"Enter phone"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.phone}
              inputName={"phone"}
              onChange={handleChange}
              {...formValidate(errors, "phone")}
            />
          </div>
        </div>
        <div className={`col-span-2 flex items-center justify-end mt-4`}>
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            buttonIcon={
              data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
          />
        </div>
      </form>
    </Modal>
  );
}
