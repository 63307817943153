import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "./slice/settingsSlice";
import profileSlice from "./slice/profileSlice,";
import authSlice from "./slice/authSlice";

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    settings: settingsSlice,
    auth: authSlice,
  },
});
