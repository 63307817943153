import React, { useEffect, useState } from "react";
import Button from "../../form/Button";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import Input from "../../form/Input";
import Select from "../../form/Select";
import { toast } from "react-toastify";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { Validation } from "../../../helpers/Validation";

const WebsiteLinkManage = ({
  websiteLinkModal,
  setWebsiteLinkModal = () => {},
  setDetails = () => {},
}) => {
  const [fields, setFields] = useState([
    { value: "", fieldName: "SelectField" },
  ]);
   
console.log(fields,"fields")
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, Validation);

 
  const data = websiteLinkModal?.data;

  useEffect(() => {
    if (data && data.length > 0) {
      const initialFields = data.map((item) => ({
        fieldName: item.fieldName,
        value: item.value,
        _id: item?._id,
      }));

      setFields(initialFields);
    }
  }, [data]);

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
  };

  const addField = () => {
    setFields((prevFields) => {
      const newFields = [...prevFields, { value: "", fieldName: "" }];
      return newFields;
    });
  };

  const removeField = (index) => {
    setFields((prevFields) => {
      const newFields = prevFields.filter((_, i) => i !== index);
      return newFields;
    });
  };

  const getAvailableOptions = (index) => {
    const selectedValues = fields.map(
      (field, idx) => idx !== index && field.fieldName
    );
    const availableOptions = [
      { name: "Website", value: "website" },
      { name: "Instagram", value: "instagram" },
      { name: "Facebook", value: "facebook" },
      { name: "Linked In", value: "linkedin" },
      { name: "Twitter", value: "twitter" },
    ];

    // Filter out the selected values from the available options
    return availableOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const onSubmit = (values) => {
    companyUpdate({
      digitalAddress: fields,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        setWebsiteLinkModal({ isOpen: false });
      } else {
        toast.error(res?.data?.message);
        setWebsiteLinkModal({ isOpen: false });
      }
    });
  };

  return (
    <div>
      <Modal
        title="Links Details"
        size="xl"
        open={websiteLinkModal.isOpen}
        onClose={() => setWebsiteLinkModal({ isOpen: false })}
        footer={null}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          {fields.map((field, index) => (
            <div key={index} className="flex items-center gap-4 mb-4">
              <div className="relative flex-1">
                <Select
                  label="Select Site"
                  selectedValue={field.fieldName}
                  dropdownData={getAvailableOptions(index)}
                  setCustomSelecedvalue={(val) =>
                    handleFieldChange(
                      { target: { name: "fieldName", value: val } },
                      index
                    )
                  }
                  {...formValidate(errors, "fieldName")}
                />
              </div>
              <div className="relative flex-1">
                <Input
                  label={"URL"}
                  inputPlaceholder={"Enter url ..."}
                  inputClasses={"pl-2"}
                  labelClasses={"!text-sm !font-medium"}
                  inputType={"url"}
                  value={field.value}
                  onChange={(e) => handleFieldChange(e, index)}
                  inputName={"value"}
                  charLimit={200}
                  {...formValidate(errors, "value")}
                />
              </div>

              <div className="flex items-center gap-2 mt-4">
                <button
                  type="button"
                  onClick={addField}
                  className="text-md text-gray-400 border border-gray-400 p-3 w-5 h-5 rounded-full flex items-center justify-center"
                  aria-label="Add new field"
                >
                  <i className="fa-regular fa-plus"></i>
                </button>

                {fields.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeField(index)}
                    className="text-red-500 p-3 w-5 h-5 rounded-full flex items-center justify-center"
                    aria-label="Remove this field"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="flex items-center justify-end mt-4 gap-4">
            <Button
              buttonLabel={"Cancel"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-transparent !border-orange-500 !text-orange-500"
              }
              buttonFunction={() => setWebsiteLinkModal({ isOpen: false })}
            />
            <Button
              buttonLabel={"Save"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonType="submit"
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default WebsiteLinkManage;
