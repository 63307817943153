import { apiRequest } from "../../util/apiUtils";

export const getEmployeeList = async (params) => {
  try {
    const res = await apiRequest("get", `/user/users-list-all`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `user/change-status`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const deleteEmployee = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `/user/delete-all`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};