import { Link, useParams } from "react-router-dom";
import EmployeeSection from "./EmployeeSection";
import Image from "../../../elements/Image";
import { employeeDetails } from "../../../../services/frontService/EmployeeService";
import { useCallback, useEffect, useState } from "react";
import EmployeeDetailsLoader from "../../../loaders/EmployeeDetailsLoader";
import NoEmpImg from "../../../../assets/images/noEmpImg.svg";

export default function EmployeeDetails() {
  const { id } = useParams();
  const [employee, setEmployee] = useState({ loading: false, data: {} });

  const loadDetails = useCallback(() => {
    if (!id) return;
    setEmployee({ loading: true, data: {} });

    employeeDetails({ id }).then((res) => {
      if (res?.status === 200) {
        setEmployee({
          loading: false,
          data: res?.data,
        });
      } else {
        setEmployee({ loading: false, data: {} });
      }
    });
  }, [id]);

  useEffect(() => loadDetails(), [loadDetails]);

  return (
    <EmployeeSection>
      {employee?.loading ? (
        <EmployeeDetailsLoader />
      ) : (
        <div className="col-span-3 max-w-5xl  bg-white shadow-md rounded-xl p-10 border border-gray-300 space-y-8 min-h-[1000px]">
          <header className="flex items-center space-x-8 border-b border-gray-200 pb-8">
            <div className="w-36 h-36 rounded-full border-2 shadow-lg">
              <Image
                src={employee?.data?.image?.url || NoEmpImg}
                alt={`${employee?.data?.firstName} ${employee?.data?.lastName}`}
                className="object-cover rounded-full"
              />
            </div>
            <div>
              <h2 className="text-4xl font-semibold text-gray-900">
                {employee?.data?.firstName} {employee?.data?.lastName}
              </h2>
              <p className="text-lg font-medium text-indigo-700 mt-1">
                {employee?.data?.positionDetails?.title}
              </p>
              <p className="text-md text-gray-500">
                {employee?.data?.departmentDetails?.name}
              </p>
            </div>
          </header>

          <section className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-regular fa-envelope h-6 w-6 text-blue-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Email</h4>
                <p className="text-gray-800">{employee?.data?.email}</p>
              </div>
            </div>
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-regular fa-phone h-6 w-6 text-green-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Phone</h4>
                <p className="text-gray-800">
                  {employee?.data?.phone || "N/A"}
                </p>
              </div>
            </div>
          </section>

          <section className="bg-gray-50 p-6 shadow-sm rounded-lg border border-gray-200 space-y-3">
            <h3 className="text-lg font-semibold text-gray-800 border-b border-gray-200 pb-2">
              Position Description
            </h3>
            <p className="text-gray-700">
              {employee?.data?.positionDetails?.description}
            </p>
          </section>

          <section className="bg-gray-50 p-6 shadow rounded-lg border border-gray-200 space-y-3">
            <h3 className="text-lg font-semibold text-gray-800 border-b border-gray-200 pb-2">
              Reports To
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {employee?.data?.chartData?.reportedToList?.length > 0 ? (
                employee.data?.chartData?.reportedToList?.map((report, i) => (
                  <Link
                    key={i}
                    to={`/directory/employee/${report?.userDetail?._id}`}
                    className="flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200 shadow-sm"
                  >
                    <div className="w-14 h-14">
                      <Image
                        src={report?.userDetail?.image || NoEmpImg}
                        alt={`${report?.userDetail?.firstName} ${report?.userDetail?.lastName}`}
                        className="w-14 h-14 rounded-full object-cover border border-gray-300"
                      />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-gray-900">
                        {report?.userDetail?.firstName}{" "}
                        {report?.userDetail?.lastName}
                      </h4>
                      <p className="text-sm text-gray-500">
                        {report?.userDetail?.positionDetails?.title}
                      </p>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-gray-500">No managers available.</p>
              )}
            </div>
          </section>

          <section className="bg-gray-50 p-6 shadow rounded-lg border border-gray-200 space-y-3">
            <h3 className="text-lg font-semibold text-gray-800 border-b border-gray-200 pb-2">
              Reporting By
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {employee?.data?.chartData?.reportedByList?.length > 0 ? (
                employee.data?.chartData?.reportedByList?.map((report, i) => (
                  <Link
                    key={i}
                    to={`/directory/employee/${report?.userDetail?._id}`}
                    className="flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200 shadow-sm"
                  >
                    <div className="w-14 h-14">
                      <Image
                        src={report?.userDetail?.image || NoEmpImg}
                        alt={`${report?.userDetail?.firstName} ${report?.userDetail?.lastName}`}
                        className="rounded-full object-cover border border-gray-300"
                      />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-gray-900">
                        {report?.userDetail?.firstName}{" "}
                        {report?.userDetail?.lastName}
                      </h4>
                      <p className="text-sm text-gray-500">
                        {report?.userDetail?.positionDetails?.title}
                      </p>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-gray-500">No direct reports available.</p>
              )}
            </div>
          </section>
        </div>
      )}
    </EmployeeSection>
  );
}
