import Celebrations from "../../../components/sections/front/home/Celebrations";
import Avaiability from "../../../components/sections/front/home/Availability";
import SearchWithDropdown from "../../../components/form/SearchWithDropdown";
import LatestNews from "../../../components/sections/front/home/LatestNews";
import ForYou from "../../../components/sections/front/home/ForYou";
import Image from "../../../components/elements/Image";

export default function Home() {
  return (
    <div className="relative w-full flex flex-col bg-white">
      <div className="relative w-full h-48 overflow-hidden">
        <Image
          effect={"blur"}
          src="https://media.licdn.com/dms/image/C4E12AQGW2kGWsFq8pw/article-cover_image-shrink_720_1280/0/1554257018707?e=2147483647&v=beta&t=nxyrubEfYZ199LSPuyqJaAzI4N9E3OdABBFvMWLff3E"
          alt="Header Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col justify-center px-12 py-10 space-y-3">
          <h1 className="text-4xl font-bold text-white">
            Welcome back, Subhajit!
          </h1>
          <div className="mt-4 ">
            <SearchWithDropdown placeholder="Search Org Company ..." />
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-6 py-8 px-12 h-full">
        <ForYou />
        <div className="col-span-9 flex flex-col gap-3">
          <LatestNews />
          <Celebrations />
          <Avaiability />
        </div>
      </div>
    </div>
  );
}
