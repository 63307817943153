import React, { useEffect, useState } from "react";
import ProfileSidebar from "../../../layouts/front/ProfileSidebar";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { useLocation } from "react-router-dom";
import {
  profileUpdate,
  profileDetails,
} from "../../../services/ProfileService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import { setTitle } from "../../../helpers/MetaTag";

const MyProfile = () => {
  setTitle("ORG Chart Admin | Profile");
  const [selectedOption, setSelectedOption] = useState("");
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState();
  const profile = useSelector((state) => state?.profile?.data);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/profile") {
      setSelectedOption("profile");
    }
  }, [location.pathname]);

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    profileDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        dispatch(setProfile(res?.data));
      } else {
        toast.error("Something went wrong!");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (details) {
      setFieldsValue({
        firstName: details?.firstName || "",
        lastName: details?.lastName || "",
        email: details?.email || "",
        phone: details?.phone || "",
      });
      if (profile?.image?.url) {
        setImage(profile?.image?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const validPhoneNumber = (number) => {
    const errors = [];
    if (number.length < 10) {
      errors.push("Phone number must be exactly 10 digits.");
    }
    if (number.length > 10) {
      errors.push("Phone number must be exactly 10 digits.");
    }
    return errors;
  };
  const onSubmit = (values) => {
    setLoading(true);
    if (values.phone) {
      const phoneNumberErrors = validPhoneNumber(values.phone);
      if (phoneNumberErrors.length > 0) {
        toast.error(phoneNumberErrors.join(" "));
        setLoading(false);
        return;
      }
    }
    profileUpdate({
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: values?.phone,
      image: image || null,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        dispatch(setProfile(res?.data));
      }
      setLoading(false);
    });
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto p-12">
        <div className="lg:flex md:block xl:gap-10 gap-6">
          <div className="xl:w-1/4 lg:w-1/4 w-full md:px-0 px-5">
            <ProfileSidebar
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              details={details}
              image={image}
              setImage={setImage}
              profile={profile}
            />
          </div>

          <div className="hidden lg:block w-px bg-gray-300 mx-6" />

          <div className="xl:w-3/4 w-full mt-20">
            <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
              <div className="input col-span-12 lg:col-span-9  rounded-md">
                <div className="text-2xl font-bold mb-3">Profile Info</div>
                <div className="grid lg:grid-cols-2 gap-3">
                  <div className="relative">
                    <Input
                      label={"FirstName"}
                      inputType={"text"}
                      value={values?.firstName}
                      inputPlaceholder={"Enter firstname..."}
                      inputName="firstName"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "firstName")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"LastName"}
                      inputType={"text"}
                      value={values?.lastName}
                      inputPlaceholder={"Enter lastname..."}
                      inputName="lastName"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "lastName")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"Email"}
                      inputType={"email"}
                      value={values?.email}
                      inputPlaceholder={"Enter email..."}
                      inputName="email"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "email")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"Phone"}
                      inputType={"number"}
                      value={values?.phone}
                      inputPlaceholder={"Enter phone..."}
                      inputName="phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex items-center justify-end gap-3 mt-4">
                  <div
                    className="flex justify-end items-center"
                    data-tooltip-id="edit-profile"
                    data-tooltip-place="left"
                  >
                    <Button
                      buttonType={"submit"}
                      buttonIcon={"fa-light fa-floppy-disk"}
                      buttonIconPosition={"left"}
                      buttonLabel="Save"
                      loading={loading}
                    />
                    <Tooltip
                      id="edit-profile"
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                      render={() => (
                        <div className="text-white">
                          Click to save profile details.
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
