import { getDepartmentList } from "../../services/frontService/DepartmentService";
import { getPositionList } from "../../services/frontService/PositionService";
import { getEmployeeList } from "../../services/frontService/EmployeeService";
import { chartDetails } from "../../services/frontService/ChartService";
import SelectDropdownSearch from "../form/SelectDropdownSearch";
import React, { useCallback, useEffect, useRef } from "react";
import { formValidate } from "../../helpers/formValidate";
import ImageUploadSingle from "../form/ImageUploadSingle";
import noEmpImg from "../../assets/images/noEmpImg.svg";
import { useDebounce } from "../../helpers/useDebounce";
import { classNames } from "../../helpers/classNames";
import useForm from "../../hooks/useForm";
import Image from "../elements/Image";
import NodeModal from "../NodeModal";
import Button from "../form/Button";
import Input from "../form/Input";
import { useState } from "react";
import { useColor } from "react-color-palette";
import ColorModal from "./ColorModal";
import { hexToHsv, hexToRgb } from "../../helpers";

export default function NodeEditPanel({
  editNode,
  empDetails,
  isSidepanelOpen,
  setEmpDetails = () => {},
}) {
  const [empAddModal, setEmpAddModal] = useState(false);
  const [colorModal, setColorModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editRole");
  const [image, setImage] = useState(null);
  const popperElRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const [color, setColor] = useColor("#71b483");

  const [posList, setPosList] = useState({ loading: false, data: [] });
  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [keywords, setKeywords] = useState({
    deptKeyword: "",
    posKeyword: "",
    empKeyword: "",
  });

  const validateHex = (hex) => {
    const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
    return hexRegex.test(hex);
  };

  const newUser = !empDetails?.data?.userId;

  const validation = newUser
    ? {
        userId: {
          required: true,
          message: "Please select an employee or add a new employee!",
        },
      }
    : {
        firstName: { required: true, message: "Please enter first name!" },
        lastName: { required: true, message: "Please enter last name!" },
        email: { required: true, message: "Please enter email!" },
        phone: { required: true, message: "Please enter phone!" },
        ...(empDetails?.data?.parentId && {
          departmentId: {
            required: true,
            message: "Please select a department !",
          },
          positionId: {
            required: true,
            message: "Please select a position !",
          },
        }),
      };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const isAssignedEmp = empList?.data?.find(({ _id }) => _id === values?.userId)
    ?.assignedDetail?.assigned;

  const deptKeyword = useDebounce(keywords.deptKeyword, 400);
  const posKeyword = useDebounce(keywords.posKeyword, 400);
  const empKeyword = useDebounce(keywords.empKeyword, 400);

  useEffect(() => {
    if (isSidepanelOpen?._id) {
      setEmpDetails({ loading: true, data: {} });
      chartDetails({ id: isSidepanelOpen._id }).then((res) =>
        setEmpDetails({
          loading: false,
          data: res?.status === 200 ? res.data : {},
        })
      );
    }
  }, [isSidepanelOpen?._id]);

  useEffect(() => {
    if (empDetails?.data?._id) {
      const { userDetails: user } = empDetails?.data;
      setImage(user?.image?.url || "");
      setIsValid(validateHex(empDetails?.data?.theme?.headerColor));
      setFieldsValue({
        positionId: user?.positionDetails?._id || "",
        departmentId: user?.departmentDetails?._id || "",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        phone: user?.phone || "",
      });
      setColor({
        hex: empDetails?.data?.theme?.headerColor,
        rgb: hexToRgb(empDetails?.data?.theme?.headerColor),
        hsv: hexToHsv(empDetails?.data?.theme?.headerColor),
      });
    }
  }, [empDetails]);

  const loadList = useCallback((type, setState, keyword, payload = {}) => {
    const apiMap = {
      depList: getDepartmentList,
      posList: getPositionList,
    };
    setState((pre) => ({ ...pre, loading: true }));
    apiMap[type]({ keyword, limit: 10, ...payload }).then((res) =>
      setState({ loading: false, data: res?.status === 200 ? res.docs : [] })
    );
  }, []);

  useEffect(() => {
    loadList("depList", setDepList, deptKeyword);
  }, [deptKeyword, loadList]);

  useEffect(() => {
    loadList("posList", setPosList, posKeyword);
  }, [posKeyword, loadList]);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: empKeyword, limit: 20, isAssigned: true }).then(
      (res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      }
    );
  }, [values?.userId, empKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const onSubmit = (values) => {
    editNode({
      userId: newUser ? values?.userId : empDetails?.data?.userDetails?._id,
      headerColor: color?.hex,
      editUser: newUser
        ? Boolean(values?.positionId || values?.departmentId)
        : true,
      ...(newUser
        ? {
            ...(values?.positionId && { positionId: values.positionId }),
            ...(values?.departmentId && { departmentId: values.departmentId }),
          }
        : {
            firstName: values?.firstName,
            lastName: values?.lastName,
            phone: values?.phone,
            email: values?.email,
            image,
            ...(empDetails?.data?.parentId
              ? {
                  ...(values?.positionId && { positionId: values.positionId }),
                  ...(values?.departmentId && {
                    departmentId: values.departmentId,
                  }),
                }
              : {
                  positionId: values.positionId || "null",
                  departmentId: values.departmentId || "null",
                }),
          }),
    });
  };

  return (
    <>
      <div
        ref={popperElRef}
        className={classNames(
          "fixed top-20 right-0 w-96 h-[calc(100vh-100px)] bg-white shadow-xl flex flex-col transition-all duration-300 rounded-tl-3xl rounded-bl-3xl overflow-hidden",
          isSidepanelOpen?.isOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="w-full h-full relative flex flex-col flex-grow">
          <div className="w-full h-12 flex ">
            <Button
              buttonHasLink={false}
              buttonLabel={"Edit Role"}
              buttonClasses={classNames(
                `!w-full !h-12 !rounded-none !border-none ${
                  newUser ? "!justify-start" : "!justify-center"
                }`,
                activeTab === "editRole"
                  ? "!bg-black !text-white"
                  : "!bg-gray-300 !text-black"
              )}
              buttonLabelClasses={`font-semibold !text-base ${
                newUser ? "pl-3" : ""
              }`}
              buttonFunction={() => setActiveTab("editRole")}
            />
            {!newUser && (
              <>
                <Button
                  buttonHasLink={false}
                  btnLoading={empDetails?.loading}
                  btnLoaderClasses={"!text-black"}
                  buttonLabel={
                    !empDetails?.loading &&
                    empDetails?.data?.userDetails?.firstName +
                      " " +
                      empDetails?.data?.userDetails?.lastName
                  }
                  buttonClasses={classNames(
                    "!w-full !h-12 !rounded-none !border-none  !justify-center",
                    activeTab === "details"
                      ? "!bg-black !text-white"
                      : "!bg-gray-300 !text-black"
                  )}
                  buttonLabelClasses={`font-semibold !text-base `}
                  buttonFunction={() => setActiveTab("details")}
                />
              </>
            )}
          </div>

          {empDetails?.loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-slate-100 bg-opacity-75  z-10">
              <span className="dot-loader text-orange-500"></span>
            </div>
          )}

          <form
            className="w-full h-full overflow-y-scroll flex flex-col flex-grow scrollbar"
            onSubmit={(event) => handleSubmit(event, onSubmit)}
          >
            {activeTab === "editRole" && (
              <>
                <div className="border-b border-slate-200 py-4 mb-3 px-5 space-y-3">
                  {["Job Title", "Department"].map((label, index) => (
                    <div key={label} className="relative col-span-6">
                      <SelectDropdownSearch
                        label={label}
                        placeholder={`Select a ${label}`}
                        xPlacement="bottom"
                        selectName={index === 0 ? "positionId" : "departmentId"}
                        isSearch={true}
                        buttonArrowIcon="fa-solid fa-chevron-down"
                        tabTextColor="text-slate-700"
                        dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                        onSearch={(val) =>
                          setKeywords((pre) => ({
                            ...pre,
                            [index === 0 ? "posKeyword" : "deptKeyword"]: val,
                          }))
                        }
                        onChange={handleChange}
                        value={
                          index === 0
                            ? values?.positionId
                            : values?.departmentId
                        }
                        loading={
                          index === 0 ? posList?.loading : depList?.loading
                        }
                        dropdownData={(index === 0
                          ? posList
                          : depList
                        )?.data?.map(({ _id, title, name }) => ({
                          name: title || name,
                          _id,
                        }))}
                        {...formValidate(
                          errors,
                          index === 0 ? "positionId" : "departmentId"
                        )}
                      />
                    </div>
                  ))}

                  <div className="relative col-span-6 space-y-2">
                    <p className="text-sm font-medium text-slate-600">
                      Selected Color{" "}
                    </p>
                    <div className="w-full flex items-center border gap-1 rounded-md p-1">
                      <div
                        style={{ background: color?.hex }}
                        className="relative w-10 h-10 flex-shrink-0 rounded-md shadow border border-slate-300 cursor-pointer"
                        onClick={() => setColorModal(true)}
                      ></div>
                      <input
                        type="text"
                        value={color?.hex || ""}
                        onChange={(e) => {
                          const newHex = e.target.value;
                          setColor({
                            hex: newHex,
                            rgb: hexToRgb(newHex),
                            hsv: hexToHsv(newHex),
                          });
                          setIsValid(validateHex(newHex));
                        }}
                        className={`text-slate-700 font-semibold border-none focus:ring-1 focus:ring-orange-300 rounded w-full ${
                          !isValid ? "border-red-500" : ""
                        }`}
                        placeholder="Enter color code"
                      />
                    </div>
                    {!isValid && (
                      <div className="text-xs font-medium text-orange-500 pl-2">
                        <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
                        Invalid color code. Please enter a valid hex code.
                      </div>
                    )}
                  </div>
                </div>
                {newUser ? (
                  <>
                    <div className="px-4">
                      <SelectDropdownSearch
                        label={"Employee"}
                        placeholder={"Select an Employee"}
                        isSearch={true}
                        xPlacement={"bottom"}
                        buttonArrowIcon={"fa-solid fa-chevron-down"}
                        tabTextColor={"text-slate-700"}
                        selectName={"userId"}
                        loading={empList?.loading}
                        onChange={handleChange}
                        dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                        value={values?.userId}
                        dropdownData={empList?.data?.map((item) => ({
                          name: item?.firstName + " " + item?.lastName,
                          _id: item?._id,
                          url: item?.image?.url ? item?.image?.url : noEmpImg,
                          icon: item?.assignedDetail?.assigned
                            ? "fa-regular fa-user-check !text-blue-500"
                            : "",
                          subItem: item?.positionDetails?.title,
                        }))}
                        onSearch={(val) => {
                          setKeywords((pre) => ({ ...pre, empKeyword: val }));
                        }}
                        {...formValidate(errors, "userId")}
                      />
                    </div>
                  </>
                ) : (
                  <div className="px-4">
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-sm font-medium text-slate-500">
                        Employee
                      </div>
                    </div>
                    <div className="relative mb-3">
                      <ImageUploadSingle
                        image={image}
                        setImage={setImage}
                        icon={"fa-regular fa-image"}
                        title={"Choose a file or drag & drop it here"}
                        note={"JPG or PNG formats, upto 5MB"}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-user text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"First Name"}
                        inputPlaceholder={"First Name"}
                        inputClasses={"pl-2"}
                        inputType={"text"}
                        value={values?.firstName}
                        inputName={"firstName"}
                        onChange={handleChange}
                        {...formValidate(errors, "firstName")}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-user text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"Last Name"}
                        inputPlaceholder={"Last Name"}
                        inputClasses={"pl-2"}
                        inputType={"text"}
                        value={values?.lastName}
                        inputName={"lastName"}
                        onChange={handleChange}
                        {...formValidate(errors, "lastName")}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputClasses={"pl-2"}
                        inputPlaceholder={"Enter Employee Email"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-phone text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"Phone No"}
                        inputClasses={"pl-2"}
                        inputPlaceholder={"Enter phone no"}
                        inputType={"text"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

                {isAssignedEmp && (
                  <div className="text-xs font-bold text-yellow-600 my-3 mx-5 border-dotted border-2 rounded-lg p-3 border-yellow-600">
                    <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
                    This employee has been already assigned a role ! If you save
                    it will replace it with the current one .
                  </div>
                )}
                <div className="py-5 flex justify-end items-end mt-auto px-4">
                  {newUser === false || (newUser === true && values?.userId) ? (
                    <Button
                      buttonHasLink={false}
                      buttonType="submit"
                      buttonLabelClasses="uppercase"
                      buttonLabel={empDetails?.loading ? "Saving..." : "Save"}
                      buttonClasses="w-full !text-white !bg-orange-500 justify-center !rounded-lg"
                    />
                  ) : newUser === true && !values?.userId ? (
                    <Button
                      buttonHasLink={false}
                      buttonLabelClasses="uppercase"
                      buttonIconPosition="left"
                      buttonIcon="fa-solid fa-user"
                      buttonLabel="New Employee"
                      buttonFunction={() => {
                        setEmpAddModal(true);
                      }}
                      buttonClasses="w-full !text-white !bg-orange-500 justify-center !rounded-lg"
                    />
                  ) : null}
                </div>
              </>
            )}

            {activeTab === "details" && (
              <EmployeeDetails empDetails={empDetails} />
            )}
          </form>

          <NodeModal
            posList={posList}
            depList={depList}
            editNode={editNode}
            isOpen={empAddModal}
            dropdownValues={{
              departmentId: values?.departmentId,
              positionId: values?.positionId,
            }}
            btnLoading={empDetails?.loading}
            setKeywords={setKeywords}
            onClose={() => setEmpAddModal(false)}
          />
          <ColorModal
            color={color}
            isOpen={colorModal}
            setColor={setColor}
            onClose={() => setColorModal(false)}
          />
        </div>
      </div>
    </>
  );
}

const EmployeeDetails = ({ empDetails }) => (
  <>
    <div className="border-b border-slate-200 py-4 mb-3 px-5 ">
      <div className="space-y-6">
        <Image
          alt="Employee Image"
          className="w-full h-64 rounded-3xl object-cover "
          src={empDetails?.data?.userDetails?.image?.url || noEmpImg}
          effect={"blur"}
        />
        <div className="relative w-full">
          <div className="text-slate-900 font-bold text-lg">
            {(empDetails?.data?.userDetails?.firstName &&
              empDetails?.data?.userDetails?.firstName +
                " " +
                empDetails?.data?.userDetails?.lastName) ||
              ""}
          </div>
          <div className="text-slate-500 font-medium text-sm">
            {empDetails?.data?.userDetails?.positionDetails?.title}
          </div>
        </div>
      </div>
    </div>
    <div className="px-4 mb-3 space-y-1">
      <div className="flex items-center space-x-2">
        <div className="w-6 text-base text-slate-500">
          <i className="fa-regular fa-fw fa-envelope"></i>
        </div>
        <div className="text-sm text-slate-500">
          {empDetails?.data?.userDetails?.email}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="w-6 text-base text-slate-500">
          <i className="fa-regular fa-fw fa-mobile"></i>
        </div>
        <div className="text-sm text-slate-500">
          {empDetails?.data?.userDetails?.phone}
        </div>
      </div>
    </div>
  </>
);
