import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Directory({ children }) {
  const profile = useSelector((state) => state?.profile?.data);

  return (
    <div className="flex w-full py-6 px-6 space-x-6 mb-4">
      <div className="flex-shrink flex-grow relative flex flex-col">
        <div className="font-semibold text-3xl text-slate-800 h-10 flex items-center">
          Directory
        </div>
        <div className="relative w-full mt-5 flex flex-col md:flex-row gap-3">
          <Link to="/directory/employees">
            <div
              className={`flex items-center justify-start shadow-sm  gap-1 border pl-5 bg-slate-50 px-10 h-16 rounded-lg text-xl hover:shadow-md hover:bg-blue-50 hover:text-blue-600 ${
                window.location.pathname === "/directory/employees" ||
                window.location.pathname.startsWith("/directory/employee")
                  ? "shadow-md bg-blue-50 text-blue-600 border-blue-300"
                  : ""
              }`}
            >
              <span className="flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full  p-2 text-blue-600 text-sm">
                <i className="fa-regular fa-face-smile" />
              </span>
              <p className="font-medium">{profile?.employeeCount}</p>
              <p className="text-sm pt-1">Employees</p>
            </div>
          </Link>
          <Link to="/directory/departments">
            <div
              className={`flex items-center justify-start shadow-sm  gap-1 border pl-5 bg-slate-50 px-10 h-16 rounded-lg text-xl hover:shadow-md hover:bg-red-50 hover:text-red-600 ${
                window.location.pathname === "/directory/departments" &&
                "shadow-md bg-red-50 text-red-600 border-red-300"
              }`}
            >
              <span className="flex items-center justify-center w-8 h-8 bg-red-100 rounded-full p-2 text-red-600 text-sm">
                <i class="fa-regular fa-thin fa-flag"></i>
              </span>
              <p className="font-medium">{profile?.departmentCount}</p>
              <p className="text-sm pt-1">Departments</p>
            </div>
          </Link>
          <Link to="/directory/positions">
            <div
              className={`flex items-center justify-start  shadow-sm  gap-1 border pl-5 bg-slate-50 px-10 h-16 rounded-lg text-xl hover:shadow-md hover:bg-emerald-50 hover:text-emerald-600 ${
                window.location.pathname === "/directory/positions" &&
                "shadow-md bg-emerald-50 text-emerald-600 border-emerald-300"
              }`}
            >
              <span className="flex items-center justify-center w-8 h-8 bg-emerald-100 rounded-full p-2 text-emerald-600 text-sm">
                <i class="fa-regular fa-users"></i>
              </span>
              <p className="font-medium">{profile?.positionCount}</p>
              <p className="text-sm pt-1">Positions</p>
            </div>
          </Link>
        </div>
        <div className="mt-4 bg-white p-4 rounded-lg shadow-md border">
          {children}
        </div>
      </div>
    </div>
  );
}
