import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import Search from "../../../components/form/Search";
import EmpImage from "../../../assets/images/member-02.webp";
import { useState } from "react";
import DatePickerInput from "../../../components/form/DatePicker";

export default function Calendar() {
  const [date, setDate] = useState(new Date());
  return (
    <div className="flex w-full p-10 px-8 space-x-6 mb-4">
      <div className="relative flex-shrink flex-grow  flex flex-col">
        <div className="flex w-full items-center justify-between">
          <div className="font-semibold text-3xl text-slate-800 h-10 flex items-center">
            Calendar
          </div>
          <div className="flex gap-2">
            <Button
              buttonIconPosition="left"
              buttonLabel="Settings"
              buttonIcon="fa-light fa-gear"
              buttonClasses="!bg-white !text-slate-700 !border-slate-300 !font-medium !text-sm hover:!bg-slate-100"
            />
            <Button
              buttonIconPosition="left"
              buttonLabel="Add Status"
              buttonIcon="fa-light fa-plus"
            />
          </div>
        </div>

        <div className="w-full grid grid-cols-12 mt-6 gap-2 ">
          <div className="col-span-8 bg-white rounded-md border border-slate-200 shadow p-6 min-h-[580px]">
            <Search placeholder={"Search by person ..."} />
            <div className="flex items-center gap-2 py-4 border-b ">
              <div className="bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200">
                Everything
              </div>
              <div className="bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200">
                Statuses
              </div>
              <div className="bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200">
                Aniversaries
              </div>
              <div className="bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200">
                Birthdays
              </div>
              <div className="bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200">
                Holidays
              </div>
            </div>
            <div className="flex flex-col space-y-2 py-3">
              {Array.from({ length: 4 }).map((_, i) => (
                <div
                  key={i}
                  className="flex items-center space-x-4 px-4 py-2 bg-white border  rounded-lg shadow-sm"
                >
                  <div className="w-12 h-w-12 relative">
                    <Image
                      src={EmpImage}
                      alt={""}
                      effect={"blur"}
                      className="w-full h-full object-cover rounded-full border"
                    />
                    <span className="absolute bottom-1 -left-2 text-[10px] text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                      <i className="fa-solid fa-cake-candles" />
                    </span>
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-semibold text-slate-700">
                      Work Anniversary
                    </p>
                    <p className="text-xs text-slate-500">
                      17 Jul • Celebrating 15 years!
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-4 bg-white rounded-md border border-slate-200 shadow p-6">
            <DatePickerInput
              startDate={date}
              setStartDate={setDate}
              dateFormat={"yyyy/MM/dd"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
