import SelectDropdownSearch from "./form/SelectDropdownSearch";
import { formValidate } from "../helpers/formValidate";
import React from "react";
import Input from "./form/Input";
import useForm from "../hooks/useForm";
import Button from "./form/Button";
import Modal from "./elements/Modal";

export default function NodeModal({
  posList,
  depList,
  isOpen,
  btnLoading,
  editNode,
  dropdownValues,
  onClose = () => {},
  setKeywords = () => {},
}) {
  const validation = {
    firstName: { required: true, message: "Please enter first name!" },
    lastName: { required: true, message: "Please enter last name!" },
    email: { required: true, message: "Please enter email!" },
    phone: { required: true, message: "Please enter phone!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  if (!isOpen) return null;

  const onSubmit = async (values) => {
    const res = await editNode({
      ...values,
      editUser: true,
      positionId: values.positionId || dropdownValues.positionId,
      departmentId: values.departmentId || dropdownValues.departmentId,
    });
    if (res?.status === 200) {
      setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        departmentId: null,
        positionId: null,
      });
      onClose();
    }
  };

  const inputProps = (name, label, placeholder, icon) => ({
    isInputGroup: true,
    inputGroupIcon: icon,
    inputGroupPosition: "left",
    label,
    inputPlaceholder: placeholder,
    inputClasses: "pl-2",
    inputType: "text",
    value: values[name],
    inputName: name,
    onChange: handleChange,
    ...formValidate(errors, name),
  });

  return (
    <Modal
      title="Add new employee"
      size="xl2"
      open={isOpen}
      onClose={onClose}
      modaltitleClasses="!text-slate-200 text-lg"
      headerClass="!bg-black !rounded-t-2xl !text-white"
    >
      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="py-2 px-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "firstName",
                  "First Name",
                  "First Name",
                  "fa-light fa-user text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "lastName",
                  "Last Name",
                  "Last Name",
                  "fa-light fa-user text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "email",
                  "Email",
                  "Enter Employee Email",
                  "fa-light fa-envelope text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "phone",
                  "Phone No",
                  "Enter phone no",
                  "fa-light fa-phone text-slate-400"
                )}
              />
            </div>
            <div className="col-span-12 border-t mt-3"></div>
            {["Job Title", "Department"].map((label, index) => (
              <div key={label} className="relative col-span-6">
                <SelectDropdownSearch
                  label={label}
                  placeholder={`Select a ${label}`}
                  xPlacement="bottom"
                  selectName={index === 0 ? "positionId" : "departmentId"}
                  isSearch
                  buttonArrowIcon="fa-solid fa-chevron-down"
                  tabTextColor="text-slate-700"
                  dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                  onSearch={(val) =>
                    setKeywords((pre) => ({
                      ...pre,
                      [index === 0 ? "posKeyword" : "deptKeyword"]: val,
                    }))
                  }
                  onChange={handleChange}
                  value={
                    index === 0
                      ? values?.positionId || dropdownValues?.positionId
                      : values?.departmentId || dropdownValues?.departmentId
                  }
                  dropdownData={(index === 0 ? posList : depList)?.data?.map(
                    ({ _id, title, name }) => ({
                      name: title || name,
                      _id,
                    })
                  )}
                  {...formValidate(
                    errors,
                    index === 0 ? "positionId" : "departmentId"
                  )}
                />
              </div>
            ))}
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center justify-end space-x-3">
              <Button
                buttonType="submit"
                buttonLabel={btnLoading ? "Saving..." : "Save"}
                buttonLabelClasses="uppercase"
                buttonClasses="!text-white !bg-orange-500 focus-visible:outline justify-center rounded-md border border-orange-500"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
