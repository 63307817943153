import EmployeeManage from "../../../modals/front/EmployeeManage";
import React, { useCallback, useEffect, useState } from "react";
import noEmpImg from "../../../../assets/images/noEmpImg.svg";
import DeleteModal from "../../../common/DeleteModal";
import Image from "../../../elements/Image";
import Button from "../../../form/Button";
import Search from "../../../form/Search";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteEmployee,
  getEmployeeList,
} from "../../../../services/frontService/EmployeeService";
import { toast } from "react-toastify";
import NoDataFound from "../../../common/NodataFound";
import EmployeeLoader from "../../../loaders/EmployeeLoader";
import MultiselectDropdown from "../../../form/MultiselectDropdown";
import { getDepartmentList } from "../../../../services/frontService/DepartmentService";
import { getPositionList } from "../../../../services/frontService/PositionService";
import { createFilter } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import Pagination from "../../../common/Pagination";
import EmployeeFilterLoader from "../../../loaders/EmployeeFilterLoader";
import Directory from "../../../../pages/front/directory/Directory";

export default function EmployeeSection({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [isEmployeeModal, setEmployeeModal] = useState({
    data: {},
    isOpen: false,
  });
  const [keywords, setKeywords] = useState({
    keyword: "",
    posKeyword: "",
    deptKeyword: "",
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [filters, setFilters] = useState({
    limit: 12,
    offset: 0,
    currentPage: 0,
  });

  const [posList, setPosList] = useState({ loading: true, data: [] });
  const [depList, setDepList] = useState({ loading: true, data: [] });

  const loadEmpList = useCallback(() => {
    setList((prev) => ({ ...prev, data: [], loading: true }));

    const payload = {
      ...filters,
      ...(keywords.keyword && { keyword: keywords.keyword }),
      ...(selectedDepartments?.length > 0 &&
        createFilter(selectedDepartments, "departmentId")),
      ...(selectedPositions?.length > 0 &&
        createFilter(selectedPositions, "positionId")),
    };

    getEmployeeList(payload).then((res) => {
      if (res?.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [
    filters,
    keywords?.keyword,
    selectedDepartments.join(""),
    selectedPositions.join(""),
  ]);

  useEffect(() => loadEmpList(), [loadEmpList]);

  const loadDeplist = useCallback(() => {
    const payload = {
      ...(keywords.deptKeyword && { keyword: keywords.deptKeyword }),
    };

    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res.docs.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: false,
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [keywords.deptKeyword]);

  useEffect(() => loadDeplist(), [loadDeplist]);

  const loadPoslist = useCallback(() => {
    setPosList({ loading: true, data: [] });

    const payload = {
      ...(keywords.posKeyword && { keyword: keywords.posKeyword }),
    };

    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPosList({ loading: false, data: res.docs });
        setPosition(
          res.docs.map(({ _id, title, positionEmpCount }) => ({
            _id,
            name: title,
            value: _id,
            checked: false,
            count: positionEmpCount,
          }))
        );
      } else {
        setPosList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [keywords.posKeyword]);

  useEffect(() => loadPoslist(), [loadPoslist]);

  const deleteFunction = () => {
    setDeleteModal((prev) => ({ ...prev, loading: true }));

    deleteEmployee({ id: deleteModal.ids }).then((res) => {
      setDeleteModal((prev) => ({ ...prev, loading: false }));

      if (res?.status === 200) {
        setList((prev) => ({
          ...prev,
          data: prev.data.filter((item) => !deleteModal.ids.includes(item._id)),
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount: pre?.employeeCount - 1,
          }))
        );
        setDeleteModal((prev) => ({ ...prev, isOpen: false }));
        setEmployeeModal((prev) => ({ ...prev, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Directory>
      <div className="w-full relative h-full grid grid-cols-4 gap-4 mb-4">
        {children ? (
          <>{children}</>
        ) : (
          <>
            <div className="col-span-4 xl:col-span-3 w-full h-full pb-6">
              <div className="  border-gray-100">
                <div className="flex justify-between items-start flex-col space-y-4 mb-4 pb-4 border-b border-slate-100 ">
                  <div className="text-lg text-gray-800">
                    Employees
                    <span className="text-[#f76310]">
                      {" "}
                      ({list?.totalItem}){" "}
                    </span>
                  </div>
                  <div className="relative flex items-center w-full space-x-3">
                    <div className="relative flex flex-col md:flex-row  w-full items-start xl:items-center md:space-x-4 space-y-4 md:space-y-0">
                      <div className="relative flex flex-col w-full lg:flex-row lg:items-center space-y-2 lg:space-y lg:space-x-4">
                        <div className="relative w-full">
                          <Search
                            placeholder={"Search someone by name"}
                            divClasses={
                              "!rounded-md !h-10 border border-slate-300"
                            }
                            search={(e) => {
                              setKeywords((pre) => ({ ...pre, keyword: e }));
                              setFilters((pre) => ({
                                ...pre,
                                offset: 0,
                                currentPage: 0,
                              }));
                            }}
                          />
                        </div>
                        <div className="relative flex items-center space-x-2">
                          <div className="relative">
                            <MultiselectDropdown
                              buttonPlaceholder={"Departments"}
                              xPlacement={"bottomLeft"}
                              isCheckbox={true}
                              isSearch={true}
                              dropdownData={department}
                              setDropdownData={setDepartment}
                              buttonArrowIconClasses={"!text-xs"}
                              buttonLabelClasses={"!text-sm !font-medium"}
                              dropdownContainer={"!w-full lg:min-w-[200px]"}
                              dropdownButtonClass={"!rounded-md !bg-slate-50"}
                              selectedItems={(val) =>
                                setSelectedDepartments(val)
                              }
                              onSearch={(e) =>
                                setKeywords((pre) => ({
                                  ...pre,
                                  deptKeyword: e,
                                }))
                              }
                            />
                          </div>
                          <div className="relative">
                            <MultiselectDropdown
                              buttonPlaceholder={"Positions"}
                              xPlacement={"bottomLeft"}
                              isCheckbox={true}
                              isSearch={true}
                              dropdownData={position}
                              setDropdownData={setPosition}
                              dropdownButtonClass={"!rounded-md !bg-slate-50"}
                              buttonArrowIconClasses={"!text-xs"}
                              buttonLabelClasses={"!text-sm !font-medium"}
                              dropdownContainer={"!w-full min-w-[200px]"}
                              selectedItems={(val) => setSelectedPositions(val)}
                              onSearch={(e) =>
                                setKeywords((pre) => ({
                                  ...pre,
                                  posKeyword: e,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="relative flex md:space-x-2 flex-row w-full">
                        <div className="relative">
                          <Button
                            buttonLabel={"New"}
                            buttonIcon={"fa-regular fa-plus"}
                            buttonIconPosition={"left"}
                            buttonClasses={"!z-0"}
                            buttonFunction={() =>
                              setEmployeeModal({ data: {}, isOpen: true })
                            }
                          />
                        </div>
                        <div className="relative ml-auto lg:hidden">
                          <Button
                            buttonHasLink={false}
                            buttonIcon={"fa-regular fa-bars"}
                            buttonIconPosition={"left"}
                            // buttonFunction={() => setBottomMenuOpen(!isBottomMenuOpen)}
                            buttonClasses={
                              "!p-0 !aspect-square flex items-center justify-center !rounded-md !text-xl !z-0"
                            }
                            buttonFunction={() => setMenuOpen(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {list?.loading ? (
                    <EmployeeLoader count={8} />
                  ) : list?.data &&
                    Array.isArray(list?.data) &&
                    list.data.length === 0 ? (
                    <div className="col-span-4">
                      <NoDataFound title="No Employees found" />
                    </div>
                  ) : (
                    list?.data?.map((emp, index) => (
                      <Link
                        to={`/directory/employee/${emp?._id}`}
                        key={index}
                        className="col-span-1 relative flex flex-col  rounded-lg overflow-hidden transition-all  bg-white p-2 border border-slate-200 shadow-sm hover:shadow-lg duration-300 emloyee-item"
                      >
                        <div className="relative w-full bg-slate-50 emloyee-img-box">
                          <div className="w-full overflow-hidden rounded-lg h-56 emloyee-item-img-wrap">
                            <Image
                              src={emp?.image?.url || noEmpImg}
                              alt={`${emp?.firstName} ${emp?.lastName}`}
                              className="object-cover !w-full !h-full"
                              effect={"blur"}
                            />
                          </div>
                          <div className="absolute top-2 right-0 px-2 py-2 bg-white rounded-l-full edit-button duration-300">
                            <div className="flex space-x-2">
                              <Button
                                buttonIcon={
                                  "fa-regular fa-trash-can text-xs font-light"
                                }
                                buttonIconPosition={"left"}
                                buttonClasses={
                                  "w-6 h-6 !border !bg-slate-50 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
                                }
                                buttonFunction={(e) => {
                                  e.preventDefault();
                                  setDeleteModal((pre) => ({
                                    ...pre,
                                    isOpen: true,
                                    ids: [emp?._id],
                                  }));
                                }}
                              />
                              <Button
                                buttonIcon={
                                  "fa-regular fa-pen-to-square text-xs font-light"
                                }
                                buttonIconPosition={"left"}
                                buttonClasses={
                                  "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
                                }
                                buttonFunction={(e) => {
                                  e.preventDefault();
                                  setEmployeeModal({ data: emp, isOpen: true });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col space-y-2 items-start justify-between py-5 px-0">
                          <div className="flex flex-col space-y-4 items-start justify-center">
                            <div className="relative space-y-1">
                              <h3 className="font-semibold text-base text-gray-800 !leading-none uppercase">
                                {`${emp?.firstName} ${emp?.lastName}`}
                              </h3>
                              <div className="text-xs text-gray-500 !leading-none">
                                {emp?.positionDetails?.title}
                              </div>
                            </div>
                            <div className="text-sm font-medium text-gray-700 !leading-none">
                              {emp?.departmentDetails?.name ||
                                "No department specified"}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>

                {list?.pageCount > 1 && (
                  <div className="relative pt-4 mt-auto">
                    <Pagination
                      paginationClasses={"mt-4 justify-center"}
                      onPageChange={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          offset: pre?.limit * val?.selected,
                          currentPage: val?.selected,
                        }));
                      }}
                      pageCount={list?.pageCount}
                      currentPage={filters?.currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
            {isMobile && menuOpen && (
              <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50"></div>
            )}
          </>
        )}
        {depList?.loading ? (
          <EmployeeFilterLoader />
        ) : (
          <>
            {(isMobile && menuOpen) || !isMobile ? (
              <div
                className={`fixed py-0 left-0 bottom-0 right-0 ${
                  isMobile ? "h-[55vh]" : "h-full"
                } w-full flex flex-row xl:flex-col px-8 xl:px-0 bg-white col-span-4 xl:col-span-1 space-x-3 xl:space-x-0 xl:space-y-3 xl:relative rounded-t-3xl flex-shrink-0`}
              >
                <div className="bg-white rounded-lg shadow-lg py-3 px-6  border border-gray-300 transition-all duration-300 hover:shadow-xl w-full">
                  <h3 className="flex items-center justify-start gap-2 font-semibold text-xl text-gray-800 mb-4 border-b pb-2">
                    <i className="fa-regular fa-building-user text-orange-500"></i>
                    Departments
                  </h3>
                  {department?.length > 0 ? (
                    <div className="flex flex-col xl:space-y-2 2xl:space-y-3 overflow-y-scroll h-full  xl:max-h-[300px] scrollbar pr-4 xl:pr-0 ">
                      {department?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setDepartment(
                              department?.map((data) =>
                                data?.value === item?.value
                                  ? {
                                      ...data,
                                      checked: !data?.checked,
                                    }
                                  : data
                              )
                            );
                            if (
                              window.location.pathname !==
                              "/directory/employees"
                            ) {
                              navigate("/directory/employees");
                            }
                          }}
                          className={`relative flex justify-between items-center py-2 2xl:px-3 rounded-md cursor-pointer transition-all duration-200 hover:bg-orange-100 hover:text-orange-600 text-gray-700 ${
                            item?.checked
                              ? "bg-orange-100 text-orange-600"
                              : "hover:bg-gray-50"
                          }`}
                        >
                          <div className="flex gap-2 items-center justify-center  font-semibold text-sm uppercase tracking-wide">
                            {item?.checked && (
                              <i className="fa-solid fa-check text-[11px]" />
                            )}
                            {item?.name}
                          </div>
                          <div className="font-semibold text-sm text-gray-600">
                            {item?.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-gray-500 text-sm">
                      No departments available.
                    </div>
                  )}
                </div>
                <div className="bg-white rounded-lg shadow-lg py-3 px-4 border border-gray-200 transition-all duration-300 hover:shadow-xl w-full">
                  <h3 className="flex items-center justify-start gap-2 font-semibold text-xl text-gray-800 mb-4 border-b pb-2">
                    <i className="fa-regular fa-users text-orange-500"></i>
                    Positions
                  </h3>
                  {position?.length > 0 ? (
                    <div className="flex flex-col space-y-3  h-full xl:max-h-[300px] scrollbar pr-4 xl:pr-0 overflow-y-scroll">
                      {position?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setPosition(
                              position?.map((data) =>
                                data?.value === item?.value
                                  ? {
                                      ...data,
                                      checked: !data?.checked,
                                    }
                                  : data
                              )
                            );
                          }}
                          className={`relative flex justify-between items-center py-2 px-3 rounded-md cursor-pointer transition-all duration-200 hover:bg-orange-100 hover:text-orange-600 text-gray-700 ${
                            item?.checked
                              ? "bg-orange-100 text-orange-600"
                              : "hover:bg-gray-50"
                          }`}
                        >
                          <div className="font-semibold text-sm uppercase tracking-wide">
                            {item?.name}
                          </div>
                          <div className="font-semibold text-sm text-gray-600">
                            {item?.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-gray-500 text-sm">
                      No positions available.
                    </div>
                  )}
                </div>
                {isMobile && (
                  <div className="absolute -top-5 left-1/2 -translate-x-2/3 z-40">
                    <Button
                      buttonHasLink={false}
                      buttonIcon={"fa-regular fa-angle-down"}
                      buttonIconPosition={"left"}
                      buttonClasses={
                        "!p-0 !aspect-square flex items-center justify-center !rounded-full !text-xl !z-30"
                      }
                      buttonFunction={() => setMenuOpen(false)}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}

        <EmployeeManage
          setList={setList}
          pos_list={posList}
          dep_list={depList}
          setDeleteModal={setDeleteModal}
          isEmployeeModal={isEmployeeModal}
          setEmployeeModal={setEmployeeModal}
        />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deleteFunction}
          title="Delete Employee"
          subTitle="You are trying to delete a employee"
        />
      </div>
    </Directory>
  );
}
