import { status_filter } from "../../../components/constant/constant";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import Search from "../../../components/form/Search";
import Table from "../../../components/elements/table/Table";
import { setTitle } from "../../../helpers/MetaTag";
import { createFilter, handleFilterChange } from "../../../helpers";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  changeStatus,
  deleteEmployee,
  getEmployeeList,
} from "../../../services/adminService/EmployeeService";
import {
  getCompanyDepartmentList,
  getCompanyList,
  getPositionList,
} from "../../../services/adminService/CompanyService";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import { useDebounce } from "../../../helpers/useDebounce";

const EmployeeList = () => {
  setTitle("ORG Chart Admin | Employees");
  const [selectedItems, setSelectedItems] = useState([]);
  const [companyList, setCompanyList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });
  const [company, setCompany] = useState("all");
  const [companyKeyword, setCompanyKeyword] = useState();
  const [departmentList, setDepartmentList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });
  const [department, setDepartment] = useState("all");
  const [departmentKeyword, setDepartmentKeyword] = useState();
  const [positionList, setPositionList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });
  const [position, setPosition] = useState("all");
  const [positionKeyword, setPositionKeyword] = useState();
  const [sort, setSort] = useState("-createdAt");
  const [keyword, setKeyword] = useState("");
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    currentPage: 0,
    status: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  const debounceSearch = useDebounce(keyword, 400);

  // loading the employee list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      const payload = {
        sortQuery: sort,
        keyword: debounceSearch,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        type: "COMPANY-EMPLOYEE",
        ...createFilter(filters?.status, "status"),
      };
      if (!Array.isArray(payload.companyId)) {
        payload.companyId = [];
      }
      if (company !== "all") {
        payload.companyId = company;
      }
      if (!Array.isArray(payload.departmentId)) {
        payload.departmentId = [];
      }
      if (department !== "all") {
        payload.departmentId[0] = department;
      }
      if (!Array.isArray(payload.positionId)) {
        payload.positionId = [];
      }
      if (position !== "all") {
        payload.positionId[0] = position;
      }
      getEmployeeList(payload).then((res) => {
        if (res && res?.status === 200) {
          setList({
            loading: false,
            data: res?.docs,
            pageCount: res?.totalPages,
            totalItem: res?.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast?.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort, company, department, position, debounceSearch]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  //loading company list && search >>>>>
  useEffect(() => {
    let payload = { type: "COMPANY" };

    if (companyKeyword) {
      payload.keyword = companyKeyword;
    }

    setCompanyList((pre) => ({ ...pre, data: [], loading: true }));
    getCompanyList(payload).then((res) => {
      if (res && res?.status === 200) {
        setCompanyList((pre) => ({
          ...pre,
          data: res?.docs || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setCompanyList((pre) => ({ ...pre, data: [], loading: true }));
        toast?.error(res?.message || "Failed to fetch Company data");
      }
    });
  }, [companyKeyword, company]);
  const handleSelectCompany = (selectedCompany) => {
    if (selectedCompany === "all") {
      setCompany("all");
      setCompanyKeyword("");
    } else {
      setCompany(selectedCompany);
    }
  };
  const handleCompanySearch = (keyword) => {
    setCompanyKeyword(keyword);
  };

  //loading department list && search >>>>>

  useEffect(() => {
    let payload = {};
    if (departmentKeyword) {
      payload.keyword = departmentKeyword;
    }
    setDepartmentList((pre) => ({ ...pre, data: [], loading: true }));
    getCompanyDepartmentList(payload).then((res) => {
      if (res && res?.status === 200) {
        setDepartmentList((pre) => ({
          ...pre,
          data: res?.docs || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setDepartmentList((pre) => ({ ...pre, data: [], loading: false }));
        toast?.error(res?.message || "Failed to fetch Company data");
      }
    });
  }, [departmentKeyword, department]);

  const handleSelectDepartment = (selectedDepartment) => {
    if (selectedDepartment === "all") {
      setDepartment("all");
      setDepartmentKeyword("");
    } else {
      setDepartment(selectedDepartment);
    }
  };

  const handleDepartmentSearch = (keyword) => {
    setDepartmentKeyword(keyword);
  };

  //loading position list && search >>>>>

  useEffect(() => {
    let payload = {};
    if (positionKeyword) {
      payload.keyword = positionKeyword;
    }
    setPositionList((pre) => ({ ...pre, data: [], loading: true }));
    getPositionList(payload).then((res) => {
      if (res && res?.status === 200) {
        setPositionList((pre) => ({
          ...pre,
          data: res?.docs || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setPositionList((pre) => ({ ...pre, data: [], loading: true }));
        toast?.error(res?.message || "Failed to fetch Company data");
      }
    });
  }, [positionKeyword, position]);
  const handleSelectPosition = (selectedPosition) => {
    if (selectedPosition === "all") {
      setPosition("all");
      setPositionKeyword("");
    } else {
      setPosition(selectedPosition);
    }
  };

  const handlePositionSearch = (keyword) => {
    setPositionKeyword(keyword);
  };
  //Reset
  const handleReset = () => {
    setKeyword("");
    setPosition("all");
    setDepartment("all");
    setCompany("all");
    setPositionKeyword("");
    setDepartmentKeyword("");
    setCompanyKeyword("");
  };

  // Status Change >>>>>>>>>>

  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem?.status === 1 ? 2 : 1;
    const res = await changeStatus({
      id: [glossaryItem?._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, status: newStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };

  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteEmployee({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          const totalItem =
            pre?.totalItem -
            (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  const tableHeadData = [
    {
      _id: 1,
      name: " Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },

    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 3,
      name: "Created Date",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-createdAt");
        } else {
          setSort("createdAt");
        }
      },
    },
    {
      _id: 4,
      name: "Company Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-companyName");
        } else {
          setSort("companyName");
        }
      },
    },

    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0,currentPage: 0 }));
      },
    },
    {
      _id: 6,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.firstName + " " + item?.lastName || "N/A",
        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.firstName && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Name: </span>
                  {item?.firstName + " " + item?.lastName}
                </h4>
              )}
              {item?.email && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Email: </span>
                  {item?.email}
                </h4>
              )}
            </div>
          </>
        ),
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt
          ? moment(item?.createdAt).format("MM-DD-YYYY")
          : "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600 `}
              >
                {item?.companyDetails?.companyName}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Delete User",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () =>
              setDeleteModal((pre) => ({
                ...pre,
                isOpen: true,
                ids: [item?._id],
              })),
          },
        ],
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Employees
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-3">
          <div className="w-full sm:w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "All Items", value: list?.totalItem },
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) => {
                handleFilterChange("limit", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div className="w-full sm:w-72">
            <Search
              placeholder={"Search here"}
              searchValue={keyword}
              isDebounce={true}
              // search={(val) => {
              //   handleFilterChange("keyword", val, setKeyword);
              //   handleFilterChange("offset", 0, setFilters);
              // }}
              search={(val) => setKeyword(val)}
            />
          </div>
          <div className="relative w-full sm:w-60">
            <Select
              xPlacement={"bottom"}
              dropdownClass={"!w-full"}
              dropdownButtonClass={"!bg-white"}
              loading={companyList?.loading}
              dropdownData={[
                ...(companyList?.data?.length > 0
                  ? [{ name: "All Companies", value: "all" }]
                  : []),
                ...(companyList?.data?.map((item) => ({
                  name: item.companyName,
                  value: item._id,
                })) || []),
              ]}
              selectedValue={company}
              isSearch={true}
              onSearch={handleCompanySearch}
              setCustomSelecedvalue={handleSelectCompany}
              placeholder={"All Companies"}
            />
          </div>
          <div className="relative w-full sm:w-60">
            <Select
              xPlacement={"bottom"}
              dropdownClass={"!w-full"}
              dropdownButtonClass={"!bg-white"}
              loading={departmentList?.loading}
              dropdownData={[
                ...(departmentList?.data?.length > 0
                  ? [{ name: "All Companies", value: "all" }]
                  : []),
                ...(departmentList?.data?.map((item) => ({
                  name: item?.name,
                  value: item?._id,
                })) || []),
              ]}
              selectedValue={department}
              isSearch={true}
              onSearch={handleDepartmentSearch}
              setCustomSelecedvalue={handleSelectDepartment}
              placeholder="All Departments"
            />
          </div>
          <div className="relative w-full sm:w-60">
            <Select
              xPlacement={"bottom"}
              dropdownClass={"!w-full"}
              dropdownButtonClass={"!bg-white"}
              loading={positionList?.loading}
              dropdownData={[
                ...(positionList?.data?.length > 0
                  ? [{ name: "All Companies", value: "all" }]
                  : []),
                ...(positionList?.data?.map((item) => ({
                  name: item?.title,
                  value: item?._id,
                })) || []),
              ]}
              selectedValue={position}
              isSearch={true}
              onSearch={handlePositionSearch}
              setCustomSelecedvalue={handleSelectPosition}
              placeholder="All Positions"
            />
          </div>

          <div data-tooltip-id="reset-employeeList" data-tooltip-place="top">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => handleReset()}
            />
            <Tooltip
              id="reset-employeeList"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Please click to reset.</div>
              )}
            />
          </div>
        </div>
      </div>

      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default EmployeeList;
