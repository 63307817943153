import React, { useCallback, useEffect, useState } from "react";
import { createFilter, handleFilterChange } from "../../../../helpers";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "../../../../components/form/Select";
import Search from "../../../../components/form/Search";
import Table from "../../../../components/elements/table/Table";
import DeleteModal from "../../../../components/common/DeleteModal";
import { status_filter } from "../../../../components/constant/constant";
import { setTitle } from "../../../../helpers/MetaTag";
import { deleteEmployee, getEmployeeList } from "../../../../services/adminService/EmployeeService";

const CompanyEmployee = ({ id }) => {
  setTitle("ORG Chart Admin | Companies");
  const [selectedItems, setSelectedItems] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [filters, setFilters] = useState({
    limit: 5,
    offset: 0,
    currentPage: 0,
    keyword: "",
    status: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  // loading the company list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      getEmployeeList({
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        companyId: id,
        type: "COMPANY-EMPLOYEE",
        ...createFilter(filters?.status, "status"),
      }).then((res) => {
        if (res && res.status === 200) {
          setList({
            loading: false,
            data: res.docs,
            pageCount: res.totalPages,
            totalItem: res.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteEmployee({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          const totalItem =
            pre?.totalItem -
            (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  const tableHeadData = [
    {
      _id: 1,
      name: " Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },

    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 3,
      name: "Created Date",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 4,
      name: "Company Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-companyName");
        } else {
          setSort("companyName");
        }
      },
    },

    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0 }));
      },
    },
    {
      _id: 6,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.firstName + " " + item?.lastName || "N/A",
        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.firstName && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Name: </span>
                  {item?.firstName + " " + item?.lastName}
                </h4>
              )}
              {item?.email && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Email: </span>
                  {item?.email}
                </h4>
              )}
            </div>
          </>
        ),
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt
          ? moment(item?.createdAt).format("MM-DD-YYYY")
          : "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600 `}
              >
                {item?.companyDetails?.companyName}
              </div>
            </div>
          </div>
        ),
      },

      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
      },
      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Delete User",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () =>
              setDeleteModal((pre) => ({
                ...pre,
                isOpen: true,
                ids: [item?._id],
              })),
          },
        ],
      },

    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5 flex-shrink-0">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Company Employees
        </div>

        <div className="flex items-center gap-3">
          <div className="w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) =>
                handleFilterChange("limit", val, setFilters)
              }
            />
          </div>
          <div className="w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
              }}
            />
          </div>
        </div>
      </div>
      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        // containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default CompanyEmployee;
