import React, { useRef } from "react";
import Image from "../elements/Image";
import NoImage from "../../assets/images/no-image.png";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const ImageUploader = ({
  label = "Upload Image",
  labelClasses = "text-lg font-bold",
  image = null,
  setImage = () => {},
  removeFile = () => {},
  buttonClasses = "",
  buttonIcon = "fa-upload",
  altImage = "",
  inputClasses = "",
  buttonIconPosition = "left",
  buttonLabel = image ? "Change Image" : "Upload Image",
  buttonLabelClasses = "",
  accepts = "image/jpeg, image/png, image/gif",
  isCameraIcon,
  removeIcon,
}) => {
  const fileInputRef = useRef(null);
  const location = useLocation();

  const isChangePasswordPage = location.pathname === "/change-password";

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      ["jpg", "jpeg", "png", "gif"].includes(file.type.split("/")[1])
    ) {
      setImage(file);
    } else {
      alert("Please upload a valid image file.");
    }
  };
  const imageSource =
    image && (image instanceof File || image.length > 0)
      ? image instanceof File
        ? URL.createObjectURL(image)
        : image
      : altImage
      ? altImage
      : NoImage;
  return (
    <div className="flex flex-col gap-4 items-center">
      {label && (
        <div
          className={`text-sm font-medium text-slate-500 mb-1 ${labelClasses}`}
        >
          {label}
        </div>
      )}

      <div className="items-center justify-center gap-4 space-y-3">
        <div className="relative group">
          <div
            className={`overflow-hidden border-4 border-slate-200 rounded-full bg-white flex items-center justify-center ${
              inputClasses ? inputClasses : "w-64 h-64"
            }`}
          >
            <Image src={imageSource} fill className="object-cover" alt="" />
            {image && !isChangePasswordPage && !removeIcon && (
              <div className="absolute rounded-full w-full h-full bg-black/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <button
                  onClick={() => {
                    setImage(null);
                    removeFile(image);
                  }}
                  type="button"
                  className="absolute flex items-center justify-center w-8 h-8 rounded-full bg-red-500 shadow text-white text-sm "
                >
                  <i className="fa-regular fa-fw fa-trash"></i>
                </button>
              </div>
            )}

            {/* Only show the camera button for the profile page */}
            {!isChangePasswordPage && isCameraIcon && (
              <div className="absolute right-2 bottom-2 p-2 bg-white rounded-full">
                <button className="text-md w-4 h-4 flex items-center justify-center  rounded-full ">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept={accepts}
                    className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                  />
                  <i className="fa-regular fa-camera text-black"></i>
                </button>
              </div>
            )}
          </div>
        </div>

        {!isChangePasswordPage && !isCameraIcon && (
          <div
            className={`relative flex justify-center items-center gap-2 border-orange-500 bg-orange-500 text-white rounded-md text-lg h-10 px-3 transition-all duration-200 cursor-pointer ${buttonClasses}`}
            data-tooltip-place="bottom"
            data-tooltip-id="image"
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept={accepts}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            {buttonIconPosition === "left" && (
              <i className={`fa-regular ${buttonIcon}`}></i>
            )}
            {buttonLabel && (
              <span className={`text-sm font-medium ${buttonLabelClasses}`}>
                {buttonLabel}
              </span>
            )}
            {buttonIconPosition === "right" && (
              <i className={`fa-fw ${buttonIcon}`}></i>
            )}
            <Tooltip
              id="image"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">
                  Click to choose and upload a file.
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
