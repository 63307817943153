import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { Validation } from "../../../helpers/Validation";
import { changePassword } from "../../../services/ProfileService";
import ProfileSidebar from "../../../layouts/front/ProfileSidebar";
import { formValidate } from "../../../helpers/formValidate";
import Button from "../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Input from "../../../components/form/Input";

const FrontPasswordChange = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [image,setImage] = useState(null);
  const profile = useSelector((state) => state?.profile?.data);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/change-password") {
      setSelectedOption("change-password");
    }
  }, [location.pathname]);


 useEffect(() => {
  if(profile?.image?.url){
    setImage(profile?.image?.url)
  }
 }, [profile])
 
  const validation = {
    currentPassword: {
      required: true,
      message: "Please enter Current Password!",
    },
    newPassword: { required: true, message: "Please enter New Password!" },
  };

  const { values, handleChange, resetField, handleSubmit, errors } = useForm(
    {},
    validation
  );

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)."
      );
    }

    return errors;
  };
  const onSubmit = async (values) => {
    setLoading(true);

    try {
      if (values.currentPassword) {
        const currentPasswordErrors = validatePassword(values.currentPassword);
        if (currentPasswordErrors.length > 0) {
          toast.error(currentPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (values.newPassword) {
        const newPasswordErrors = validatePassword(values.newPassword);
        if (newPasswordErrors.length > 0) {
          toast.error(newPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmPassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password don't match");
        setLoading(false);
        return;
      }

      const res = await changePassword({
        currentPassword: values.currentPassword || "",
        newPassword: values.newPassword || "",
        confirmPassword: values.confirmPassword || "",
      });
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message);
      } else {
        toast.error(res.message || res.errors || "Something went wrong");
      }
      resetField({ currentPassword: "", newPassword: "", confirmPassword: "" });
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto p-12">
      <div className="lg:flex md:block xl:gap-10 gap-6">
        <div className="xl:w-1/4 lg:w-1/4 w-full md:px-0 px-5">
          <ProfileSidebar
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            image={image}
          />
        </div>

        <div className="hidden lg:block w-px bg-gray-300 mx-6" />
        <div className="xl:w-3/4 w-full mt-28">
        <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
          <div className="input col-span-12 lg:col-span-9  rounded-md">
            <div className="text-2xl font-bold mb-3">Change Password</div>
            <div className="grid lg:grid-cols-3 gap-3">
              <div className="relative">
                <Input
                  label={"Current Password"}
                  inputType={"password"}
                  value={values?.currentPassword}
                  inputPlaceholder={"Enter current password..."}
                  inputName="currentPassword"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "currentPassword")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"New Password"}
                  inputType={"password"}
                  value={values?.newPassword}
                  inputPlaceholder={"Enter new password..."}
                  inputName="newPassword"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "newPassword")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Confirm Password"}
                  inputType={"password"}
                  value={values?.confirmPassword}
                  inputPlaceholder={"Enter confirm password..."}
                  inputName="confirmPassword"
                  onChange={handleChange}
                  {...formValidate(errors, "confirmPassword")}
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div
                className="flex justify-end items-center"
                data-tooltip-id="change-password"
                data-tooltip-place="left"
              >
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel="Change Password"
                  loading={loading}
                />
                <Tooltip
                  id="change-password"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to change password.</div>
                  )}
                />
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FrontPasswordChange;
